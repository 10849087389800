import { BrowserView, MobileView } from 'react-device-detect';
import PartnershipsMobilePage from "./mobile";

export default function PartnershipsPage() {

  return (
    <>
      <BrowserView>

      </BrowserView>
      <MobileView>
        <PartnershipsMobilePage />
      </MobileView >
    </>
  );
}
