import { Grid } from "@mui/material";
import { DrawerStatus, SideDrawer } from "../../kit/SideDrawer";
import { useEffect, useRef, useState } from "react";
import { AlertType } from "../../kit/Alert";
import { createPromotion, PromotionDTO } from "../../../services/promotions";
import DateInput from "../../kit/Inputs/Date";
import Checkbox from "../../kit/Selectors/Checkbox";
import Typography from "../../kit/Typography";
import TextInput from "../../kit/Inputs/Text";
import NumberInput from "../../kit/Inputs/Number";

import "./CreatePromotionDrawer.scss";
import ButtonLevel from "../../kit/Buttons";
import icons from "../../../utils/icons";
import { primaryColor1, primaryColor2 } from "../../../utils/VARIABLES";
import { AutocompleteSearch } from "../../kit/Search";
import { getProductByCodeOrName, Product } from "../../../services/product";

interface Props {
  setAlertStatus: React.Dispatch<React.SetStateAction<AlertType>>;
  setOpenDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  openDrawer: boolean;
  updatePage: () => void;
  title: string;
}
export function CreatePromotionDrawer(props: Props) {
  const defaultPromotionDTO: PromotionDTO = {
    name: "",
    amount: 0,
    products: [],
    endDate: null
  }

  const [drawerStatus, setDrawerStatus] = useState<DrawerStatus>("idle");
  const [hasEndDate, setHasEndDate] = useState(false);
  const [promotionDTO, setPromotionDTO] = useState<PromotionDTO>(defaultPromotionDTO);
  const [search, setSearch] = useState<{
    value: string;
    loading: boolean;
    options: {
      id: number;
      label: string;
    }[];
  }>({
    value: "",
    options: [],
    loading: false,
  });

  const handleSearchProductOptions = () => {
    if (search.value === "") {
      setSearch({
        ...search,
        options: [],
        loading: false
      });
      return;
    }
    setSearch({
      ...search,
      loading: true
    });
    (async () => {
      const response = await getProductByCodeOrName(search.value);
      if (response.status === 200) {
        if (response.data)
          response.data.products = response.data?.products.filter((product: Product) => !promotionDTO.products.map(p => p.productId).includes(product.id)) || [];
        setSearch({
          ...search,
          options: response.data?.products?.map((product: Product) => ({ label: product.name, id: product.id })) || [],
          loading: false
        });
      }
    })();
  }

  const timeoutIdRef = useRef<NodeJS.Timeout | number | null>(null);
  useEffect(() => {
    // clear the timeout and prevent the callback from being called
    if (timeoutIdRef.current !== null) clearTimeout(timeoutIdRef.current);

    timeoutIdRef.current = setTimeout(handleSearchProductOptions, 500);
  }, [search.value]);

  const handlePostNewPromotion = () => {
    setDrawerStatus("loading");
    (async () => {
      let response = await createPromotion(promotionDTO);

      if (response !== undefined) {
        props.updatePage();
        setDrawerStatus("success");
      } else {
        setDrawerStatus("error");
      }
    })();
  };

  const onCloseModal = () => {
    props.setOpenDrawer(false);
    setPromotionDTO(defaultPromotionDTO);
  };

  const disablePromotionDTO = () => {
    return !promotionDTO.amount || !promotionDTO.name || !promotionDTO.products.length
  }

  return (
    <SideDrawer
      drawerStatus={drawerStatus}
      onRetry={() => setDrawerStatus("idle")}
      title={props.title}
      disabled={disablePromotionDTO()}
      saveOnclick={handlePostNewPromotion}
      closeDrawer={onCloseModal}
      open={props.openDrawer}
      saveLabel="Guardar"
    >
      <Grid className="create-promotion-drawer" container>
        <Grid container className="input-drawer">
          <TextInput
            value={promotionDTO.name}
            placeholder="Nombre de la promoción"
            onChange={(name) =>
              setPromotionDTO((prevState) => ({
                ...prevState,
                name,
              }))
            }
            label={<span className="required-label">Nombre </span>}
          />
        </Grid>
        <Grid className="input-drawer" container>
          <NumberInput
            value={promotionDTO.amount}
            placeholder="Agregar monto"
            onChange={(amount) =>
              setPromotionDTO((prevState) => ({
                ...prevState,
                amount,
              }))
            }
            label={<span className="required-label">Monto </span>}
          />
        </Grid>
        <Grid container className="input-drawer add-product-search">
          <Typography variant="paragraph-14">Agregar producto en la promoción</Typography>
          <AutocompleteSearch
            onChange={(value) => setSearch({
              ...search,
              value
            })}
            selectOption={(id: string | number) => {
              const productSelected = search.options.find(option => option.id === id);
              if (productSelected) {

                setPromotionDTO({
                  ...promotionDTO, products: [...promotionDTO.products, {
                    productId: productSelected.id,
                    quantity: 1,
                    name: productSelected.label
                  }]
                })
              }
            }}
            value={search.value}
            placeholder="Buscar producto"
            options={search.options}
            loading={search.loading}
            noOptionText="No se encontraron productos"
          />
        </Grid>
        <Grid container className="input-drawer">
          {promotionDTO.products.length > 0 && (
            <Grid container className="products">
              {promotionDTO.products.map(product => (
                <Grid container className="product" alignItems="center">
                  <Grid item xs={5}>
                    <Typography>{product.name}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <NumberInput
                      variant="line"
                      value={product.quantity}
                      placeholder="Agregar monto"
                      onChange={(quantity) => {
                        const products = promotionDTO.products.map(p => p.productId === product.productId ? {
                          quantity,
                          productId: p.productId,
                          name: p.name
                        } : p)
                        setPromotionDTO({ ...promotionDTO, products })
                      }}
                      label=""
                    />
                  </Grid>
                  <Grid container xs={1}>
                    <ButtonLevel
                      align="center"
                      title="X"
                      size="small"
                      onClick={() => {
                        const products = promotionDTO.products.filter(p => p.productId !== product.productId)
                        setPromotionDTO({ ...promotionDTO, products })
                      }}
                      variant="text"
                      color="error"
                    />
                  </Grid>
                </Grid>
              ))}
            </Grid>
          )}
        </Grid>

        <Grid className="date-promotion-drawer input-drawer" container>
          <Grid container className="enable-end-date" alignItems="center">
            <Grid item xs={1}>
              <Checkbox
                checked={hasEndDate}
                onClick={() => {
                  if (!promotionDTO.endDate) setPromotionDTO({ ...promotionDTO, endDate: new Date().toDateString() });
                  setHasEndDate(!hasEndDate);
                }}
              />
            </Grid>
            <Grid item xs>
              <Typography variant="paragraph-16">Habilitar fecha de expiración</Typography>
            </Grid>
          </Grid>
          <Grid container className="form_input">
            {hasEndDate && (
              <DateInput
                label={<span className="required-label">Fecha de expiración </span>}
                date={promotionDTO.endDate as string}
                onChange={(endDate) => setPromotionDTO({ ...promotionDTO, endDate })}
                variant="calendar"
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    </SideDrawer>
  )
}