import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import "./UpdateSupplierDrawer.scss";
import { DrawerStatus, SideDrawer } from "../../kit/SideDrawer";
import { AlertType } from "../../kit/Alert";
import {
  PaginatedResponse,
  defaultPaginatedResponse,
} from "../../../utils/generalServiceResponses";
import {
  getAllSuppliers,
  Supplier,
  updateSupplier,
  UpdateSupplierDTO,
} from "../../../services/supplier";
import TextInput from "../../kit/Inputs/Text";
interface Props {
  supplier: Supplier;
  title: string;
  setAlertStatus: React.Dispatch<React.SetStateAction<AlertType>>;
  setOpenModal: React.Dispatch<React.SetStateAction<Supplier | null>>;
  openModal: boolean;
  updatePage: () => void;
}

export default function UpdateSupplierDrawer(props: Props) {
  const [drawerStatus, setDrawerStatus] = useState<DrawerStatus>("idle");
  const [supplierUpdateDTO, setUpdateSupplierDTO] = useState<UpdateSupplierDTO>(
    {
      id: props.supplier?.id,
      name: props.supplier?.name,
      phone: props.supplier?.phone,
      email: props.supplier?.email,
    }
  );
  const [supplierPaginated, setSupplierPaginated] = useState<
    PaginatedResponse<Supplier>
  >(defaultPaginatedResponse);

  const handleSupplierData = () => {
    (async () => {
      let data = await getAllSuppliers(undefined, true);

      if (data !== undefined) {
        setSupplierPaginated(data);
      }
    })();
  };

  useEffect(() => {
    handleSupplierData();
  }, []);

  const handleUpdateSupplier = () => {
    (async () => {
      let response = await updateSupplier(supplierUpdateDTO);

      if (response !== undefined) {
        props.updatePage();
        setDrawerStatus("success");
      } else {
        setDrawerStatus("error");
      }
    })();
  };

  const disableSupplierDTO = () => {
    if (!supplierUpdateDTO.name) return true;

    return false;
  };
  const onCloseModal = () => {
    props.setOpenModal(null);
  };

  return (
    <Grid className="update-supplier-modal">
      <SideDrawer
        saveLabel="Guardar"
        drawerStatus={drawerStatus}
        title="Editar proveedor"
        disabled={disableSupplierDTO()}
        saveOnclick={handleUpdateSupplier}
        closeDrawer={onCloseModal}
        open={props.openModal}
      >
        <Grid container className="update-supplier-modal-body">
          <Grid container className="update-input-drawer">
            <TextInput
              placeholder="Agregar nombre"
              value={supplierUpdateDTO.name as string}
              onChange={(name) =>
                setUpdateSupplierDTO((prevState) => ({
                  ...prevState,
                  name,
                }))
              }
              label="Nombre"
            />
          </Grid>
          <Grid container className="update-input-drawer">
            <TextInput
              placeholder="Agregar numero"
              value={supplierUpdateDTO.phone as string}
              onChange={(phone) =>
                setUpdateSupplierDTO((prevState) => ({
                  ...prevState,
                  phone,
                }))
              }
              label="Numero"
            />
          </Grid>
          <Grid container className="update-input-drawer">
            <TextInput
              placeholder="Agregar numero"
              value={supplierUpdateDTO.email as string}
              onChange={(email) =>
                setUpdateSupplierDTO((prevState) => ({
                  ...prevState,
                  email,
                }))
              }
              label="Email"
            />
          </Grid>
        </Grid>
      </SideDrawer>
    </Grid>
  );
}
