import { Grid } from "@mui/material";
import Typography from "../../Typography";
import { ButtonLevelColor } from "../../Buttons";
import "./ConfirmationModal.scss";
import BasicModal from "..";
interface Props {
  open: boolean;
  closeModal: () => void;
  title: string;
  description: string | React.ReactElement;
  disabled?: boolean;
  confirmationOption: {
    onclick: () => void;
    title: string;
    color?: ButtonLevelColor;
  };
}

export default function ConfirmationModal(props: Props) {
  return (
    <Grid container>
      <BasicModal
        open={props.open}
        buttons={{
          save: {
            onClick: props.confirmationOption.onclick,
            title: props.confirmationOption.title,
            color: props.confirmationOption.color,
          },
          cancel: {
            onClick: props.closeModal,
            title: "Cancelar",
            color: "secondary",
          },
        }}
      >
        <Grid container className="confirmation-modal-container">
          <Grid item xs={12} className="confirmation-modal-title">
            <Typography variant="paragraph-18" type="bold">{props.title}</Typography>
          </Grid>
          <Grid item xs={12} className="confirmation-modal-description">
            <Typography variant="paragraph-16">{props.description}</Typography>
          </Grid>
        </Grid>
      </BasicModal>


    </Grid>
  );
}
