export const whiteColor = "#FFFFFF";

export const primaryColorLight = "#e88e7299";
export const grayColor = "#7987A4";
export const successColor = "#51C6A1";

export const primaryColor1 = "#e88e72";
export const primaryColor2 = "#8b3bd5";

export const secondaryColor1 = "#E5E8F7";
export const secondaryColor2 = "#AFB9CE";
export const secondaryColor3 = "#7987A4";

export const tertiaryColor1 = "#E4CFFA";
export const tertiaryColor2 = "#8B3BD5";
export const tertiaryColor3 = "#6E19BA";

export const darkColor1 = "#2D3749";
export const darkColor2 = "#151F2F";
export const darkColor3 = "#10151F";

export const yellowColor1 = "#FFEE57";
export const yellowColor2 = "#FFE066";
export const yellowColor3 = "#E09900";

export const redColor1 = "#FF5975";
export const redColor2 = "#F51B5C";
export const redColor3 = "#FF8398";

export const greenColor1 = "#BDE7DA";
export const greenColor2 = "#51C6A1";
export const greenColor3 = "#036D4C";

export const backgroundColor1 = "#FFFFFF";
export const backgroundColor2 = "#F5F6FC";
export const backgroundColor3 = "#D7DBF1";
export const backgroundColor4 = "#E4E9FF";
