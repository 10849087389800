import axios from "axios";
import { API_URL } from "../../config/env";
import { getToken } from "../utils/token";
import {
  GetResponse,
  PaginateOptions,
  defaultPaginateOptions,
} from "../../utils/generalServiceResponses";
import { PaginatedResponse } from "../../utils/generalServiceResponses";
import { defaultPaginatedResponse } from "../../utils/generalServiceResponses";
import { Product } from "../product";

export type Supplier = {
  id: number;
  name: string;
  phone: string;
  email: string;
};

export async function getSupplierById(id: number): Promise<GetResponse<Supplier>> {
  const token = await getToken();

  try {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios.get(API_URL + "/supplier/" + id, {
      headers,
    });
    return response;
  } catch (error: any) {
    return error.response;
  }
}

export async function getAllSuppliers(
  paginateOptions?: PaginateOptions,
  isAvailable?: boolean
): Promise<PaginatedResponse<Supplier>> {
  if (paginateOptions === undefined)
    paginateOptions = { ...defaultPaginateOptions, limit: 100 };

  const token = await getToken();

  try {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios.get(API_URL + "/supplier", {
      headers,
      params: {
        search: paginateOptions.search,
        page: paginateOptions.page,
        limit: paginateOptions.limit,
        isAvailable,
      },
    });
    return response.data;
  } catch (e) {
    console.warn(e);
    return defaultPaginatedResponse;
  }
}

export type SupplierDTO = {
  name: string;
  phone: string;
  email: string;
};

export async function postNewSupplier(DTO: SupplierDTO) {
  const token = await getToken();

  try {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios.post(API_URL + "/supplier", DTO, {
      headers,
    });
    return response.data;
  } catch (e) {
    console.warn(e);
  }
}

export async function deleteSupplier(id: number) {
  const token = await getToken();

  try {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios.delete(API_URL + "/supplier/" + id, {
      headers,
    });
    return response.data;
  } catch (e) {
    console.warn(e);
  }
}
export type UpdateSupplierDTO = {
  id: number;
  name: string;
  phone: string;
  email: string;
};

export async function updateSupplier(DTO: UpdateSupplierDTO) {
  const token = await getToken();
  try {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios.patch(API_URL + "/supplier", DTO, { headers });
    return response.data;
  } catch (e) {
    console.warn(e);
  }
}
