import { Grid } from "@mui/material";
import BasicModal from "../../kit/Modal";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../services/user";
import { CheckoutShift, getCheckoutData, openCheckout, validatePin } from "../../../services/checkout";
import Typography from "../../kit/Typography";

import "./OpenCheckoutModal.scss";
import Radio from "../../kit/Selectors/Radio";
import NumberInput from "../../kit/Inputs/Number";

export default function OpenCheckoutModal() {
  const { user } = useContext(UserContext);
  const [open, setOpen] = useState(false);

  const [step, setStep] = useState<number>(0);
  const [checkoutShiftSelected, setCheckoutShiftSelected] = useState<CheckoutShift | null>(null);
  const [pin, setPin] = useState<string>("");
  const [status, setStatus] = useState<"idle" | "loading" | "success" | "error">(
    "idle"
  );
  const [pinLocalSaved, setPinLocalSaved] = useState({
    open: false,
    writingPin: "",
    pin: ""
  });

  useEffect(() => {
    if (pinLocalSaved.pin === "") return;

    localStorage.setItem("checkout-pin", pinLocalSaved.pin);
  }, [pinLocalSaved, pinLocalSaved.pin]);

  const handleValidatePin = () => {
    let localPin = localStorage.getItem("checkout-pin");

    if (localPin === null) {
      setPinLocalSaved({
        open: true,
        writingPin: "",
        pin: ""
      });
      return;
    }

    (async () => {
      const req = await validatePin(localPin);
      if (req && !req.isValid) {
        setPinLocalSaved({
          open: true,
          writingPin: "",
          pin: ""
        });
      }
    })();
  }

  const handleOpenCheckout = () => {
    setStatus("loading");
    (async () => {
      const checkout = await openCheckout({ shift: checkoutShiftSelected as CheckoutShift, pin });
      if (!checkout) {
        setStatus("error");
      }
      setStatus("success");
      localStorage.setItem("checkout-pin", checkout.pin);
    })();
  }

  useEffect(() => {
    (async () => {
      if (user && user.roles[0].role === "employee" && user.acceptTYC) {
        const checkout = await getCheckoutData();
        if (!checkout) setOpen(true);
        else handleValidatePin();
      }
    })();
  }, [user]);

  const disabled = (step === 0 && !checkoutShiftSelected) || (step === 1 && pin.length < 4);

  return (
    <Grid container className="open-checkout-modal">
      {step <= 1 && (
        <BasicModal
          fullOpacity
          open={open}
          disabledClose
          buttons={{
            save: {
              title: step === 0 ? "Continuar" : "Aceptar",
              onClick: () => {
                if (step === 1 && status === "idle") handleOpenCheckout();
                setStep(step + 1);
              }
            }
          }}
          disabled={disabled}
          children={(
            <Grid container className="open-checkout-modal-content">
              {step === 0 && selectCheckoutShift(checkoutShiftSelected, setCheckoutShiftSelected)}
              {step === 1 && selectPin(pin, setPin)}
            </Grid>
          )}
        />
      )}
      {pinLocalSaved.open && (
        <BasicModal
          open={pinLocalSaved.open}
          disabledClose
          fullOpacity
          buttons={{
            save: {
              title: "Aceptar",
              onClick: () => {
                setPinLocalSaved(prev => ({ ...prev, pin: prev.writingPin }));
                window.location.reload();
              }
            },
            cancel: {
              title: "Cancelar",
              onClick: () => setPinLocalSaved(prev => ({ ...prev, open: false, writingPin: "" }))
            }
          }}
          disabled={pinLocalSaved.writingPin.length < 4}
          children={(
            <Grid container className="open-checkout-modal-content">
              {pinLocalSavedModal(
                pinLocalSaved.writingPin,
                (pin: string) => setPinLocalSaved(prev => ({ ...prev, open: true, writingPin: pin }))
              )}
            </Grid>
          )}
        />
      )}
    </Grid>
  );
}

function selectCheckoutShift(
  checkoutShiftSelected: CheckoutShift | null,
  setCheckoutShiftSelected: (checkoutShift: CheckoutShift) => void
) {
  return (
    <Grid container className="open-checkout-modal-select-shift">
      <Grid container className="open-checkout-modal-content-title">
        <Typography variant="paragraph-20" type="semibold">
          Abrir caja
        </Typography>
      </Grid>
      <Grid container className="open-checkout-modal-content-info">
        <Typography variant="paragraph-18">
          Ingresá tu turno para abrir caja.
        </Typography>
      </Grid>
      <Grid container className="open-checkout-modal-content-radios">
        <Grid container alignItems="center">
          <Grid item xs={2}>
            <Radio checked={checkoutShiftSelected === CheckoutShift.MORNING} onChange={() => setCheckoutShiftSelected(CheckoutShift.MORNING)} />
          </Grid>
          <Grid item xs>
            <Typography variant="paragraph-18">
              Turno mañana
            </Typography>
          </Grid>
        </Grid>
        <Grid container alignItems="center">
          <Grid item xs={2}>
            <Radio checked={checkoutShiftSelected === CheckoutShift.AFTERNOON} onChange={() => setCheckoutShiftSelected(CheckoutShift.AFTERNOON)} />
          </Grid>
          <Grid item xs>
            <Typography variant="paragraph-18">
              Turno tarde
            </Typography>
          </Grid>
        </Grid>
        <Grid container alignItems="center">
          <Grid item xs={2}>
            <Radio checked={checkoutShiftSelected === CheckoutShift.NIGHT} onChange={() => setCheckoutShiftSelected(CheckoutShift.NIGHT)} />
          </Grid>
          <Grid item xs>
            <Typography variant="paragraph-18">
              Turno noche
            </Typography>
          </Grid>
        </Grid>
        <Grid container alignItems="center">
          <Grid item xs={2}>
            <Radio checked={checkoutShiftSelected === CheckoutShift.EXTRA} onChange={() => setCheckoutShiftSelected(CheckoutShift.EXTRA)} />
          </Grid>
          <Grid item xs>
            <Typography variant="paragraph-18">
              Turno extra
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

function selectPin(
  pin: string,
  setPin: (pin: string) => void
) {
  return (
    <Grid container className="open-checkout-modal-select-pin">
      <Grid container className="open-checkout-modal-content-title">
        <Typography variant="paragraph-20" type="semibold">
          Ingresá un pin
        </Typography>
      </Grid>
      <Grid container className="open-checkout-modal-content-pin">
        <NumberInput
          label="Ingresá un pin para tu turno"
          value={pin}
          onChange={(value) => setPin(value.toString())}
          maxLengthMessage={8}
        />
      </Grid>
    </Grid>
  );
}

function pinLocalSavedModal(
  pin: string,
  setPin: (pin: string) => void
) {
  return (
    <Grid container className="open-checkout-modal-content select-pin">
      <Grid container className="open-checkout-modal-content-title">
        <Typography variant="paragraph-20" type="semibold">
          Ingresá tu pin
        </Typography>
      </Grid>
      <Grid container className="open-checkout-modal-content-pin">
        <NumberInput
          label="Ingresá tu pin para continuar"
          value={pin}
          onChange={(value) => setPin(value.toString())}
          maxLengthMessage={8}
        />
      </Grid>
    </Grid>
  )
}