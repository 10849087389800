import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { ClickAwayListener, Grid, Pagination, Popper } from "@mui/material";
import "./BasicTable.scss";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import ButtonLevel from "../Buttons";
import SimpleSearch, { AutocompleteSearch } from "../Search";
import Typography from "../Typography";
import Skeleton from "@mui/material/Skeleton";
import { primaryColor1 } from "../../../utils/VARIABLES";
import { ReactNode } from "react";
import icons from "../../../utils/icons";

export type BasicTableStatus = "idle" | "loading" | "success" | "error";
interface Props {
  rightElement?: ReactNode;
  head: string | JSX.Element;
  headOptions?: {
    label: string;
    onClick: () => void;
    show?: boolean | ((rowIndex: number) => boolean);
    disabled?: boolean | ((rowIndex: number) => boolean);
  }[];
  columns: string[];
  rows: (string | number | JSX.Element)[][];
  status: BasicTableStatus;
  options?: {
    label: string;
    onClick: (rowIndex: number) => void;
    show?: boolean | ((rowIndex: number) => boolean);
    disabled?: boolean | ((rowIndex: number) => boolean);
  }[];
  search?: {
    onChange: (_value: string) => void;
    placeholder: string;
    value: string;
    autocomplete?: boolean;
    options?: {
      id: number | string;
      label: string;
    }[];
    selectOption?: (id: number | string) => void;
    loading?: boolean;
    noOptionText?: string;
  };
  paginateOptions?: {
    currentPage: number;
    rowsPerPage: number;
    setCurrentPage: (newPage: number) => void;
    totalItems: number;
  };
  noFoundMessage?: {
    title: string;
    description: string;
  }
}

export default function BasicTable(props: Props) {
  // anchorEl nos dice donde esta ubicado los 3 puntitos
  //de esta forma podemos poner el Popper en el lugar correcto
  const [anchorElRowOptions, setAnchorElRowOptions] =
    React.useState<null | HTMLElement>(null);
  const [anchorElHeaderOptions, setAnchorElHeaderOptions] =
    React.useState<null | HTMLElement>(null);
  const [indexSelected, setIndexSelected] = React.useState<number>(0);
  const { rightElement } = props;

  // Si anchorEl tiene elemento entonces abrimos
  const openRowOptions = Boolean(anchorElRowOptions);
  const openHeaderOptions = Boolean(anchorElHeaderOptions);
  const id = openRowOptions || openHeaderOptions ? "simple-popper" : undefined;

  // funcion encargada de abrir el popper para las filas
  const handleClick = (
    event: React.MouseEvent<HTMLElement>,
    indexSelected: number
  ) => {
    setIndexSelected(indexSelected);
    setAnchorElRowOptions(anchorElRowOptions ? null : event.currentTarget); // seteo el anchorEl con el elemento actual (con los 3 puntitos)
  };

  // funcion encargada de abrir el popper para el head
  const handleClickHead = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElHeaderOptions(
      anchorElHeaderOptions ? null : event.currentTarget
    ); // seteo el anchorEl con el elemento actual
  };

  const handleChangePage = (
    _event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    props.paginateOptions?.setCurrentPage(page);
  };

  const onClosePopper = () => {
    setAnchorElRowOptions(null);
    setAnchorElHeaderOptions(null);
  };

  return (
    <Grid className="table" container>
      <Grid container>
        <TableContainer component={Paper} className="table-container">
          <Grid className="table-head" container alignItems="center">
            <Grid item xs={3}>
              {typeof props.head == "string" ? (
                <Typography type="medium" variant="h2">
                  {props.head}
                </Typography>
              ) : (
                props.head
              )}
            </Grid>
            <Grid
              item
              xs={9}
              className="table-head-options"
              container
              justifyContent="flex-end"
            >
              {rightElement}
            </Grid>
          </Grid>
          <Grid className="search-and-options" container>
            <Grid item xs={props.headOptions ? 10 : 12}>
              {props.search && (
                <Grid className="search">
                  {!!props.search.options ? (
                    <AutocompleteSearch
                      value={props.search.value}
                      onChange={props.search.onChange}
                      placeholder={props.search.placeholder}
                      options={props.search.options || []}
                      loading={props.search.loading || false}
                      selectOption={props.search.selectOption || (() => { })}
                      noOptionText={props.search.noOptionText}
                    />
                  ) : (
                    <SimpleSearch
                      value={props.search.value}
                      onChange={props.search.onChange}
                      placeholder={props.search.placeholder}
                    />
                  )}
                </Grid>
              )}
            </Grid>
            <Grid item xs>
              {props.headOptions && (
                <Grid container alignItems="center" justifyContent="right">
                  <button
                    style={{
                      backgroundColor: "transparent",
                      border: 0
                    }}
                    type="button"
                    onClick={(event) => handleClickHead(event)}
                  >
                    {icons.filter({ width: 30, height: 30 })}
                  </button>
                </Grid>
              )}
            </Grid>
          </Grid>
          {(props.status === "loading" ||
            (props.status === "success" && props.rows.length > 0)) && (
              <>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      {props.columns.map((e, i) => (
                        <TableCell align="left" key={i}>
                          <Typography
                            variant="table-head"
                            type="semibold"
                            text={e}
                          />
                        </TableCell>
                      ))}
                      {props.options && (
                        <TableCell align="right">
                          <Typography
                            variant="table-head"
                            type="semibold"
                            text="Acciones"
                          />
                        </TableCell>
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {props.status === "loading" &&
                      Array(5)
                        .fill(0)
                        .map((_, i) => (
                          <TableRow key={i}>
                            {Array(props.columns.length + 1)
                              .fill(0)
                              .map((_, i) => (
                                <TableCell align="left" key={i}>
                                  <Skeleton />
                                </TableCell>
                              ))}
                          </TableRow>
                        ))}
                    {props.status === "success" &&
                      props.rows.length > 0 &&
                      props.rows.map((row, rowIndex) => (
                        <TableRow key={rowIndex}>
                          {row.map((e, i) => (
                            <TableCell align="left" key={i}>
                              {typeof e === "string" ? (
                                e && e.length ? (
                                  <Typography variant="table-content" text={e} />
                                ) : (
                                  "-"
                                )
                              ) : e ? (
                                e
                              ) : (
                                "-"
                              )}
                            </TableCell>
                          ))}
                          {props.options && (
                            <TableCell align="right">
                              <button
                                style={{
                                  backgroundColor: "transparent",
                                  border: 0,
                                  justifyContent: "left",
                                }}
                                type="button"
                                onClick={(event) => handleClick(event, rowIndex)}
                              >
                                {/* 3 puntitos icono */}
                                <MoreVertOutlinedIcon color="secondary" />
                              </button>
                            </TableCell>
                          )}
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
                {props.paginateOptions &&
                  props.paginateOptions.rowsPerPage <
                  props.paginateOptions.totalItems && (
                    <Grid className="pagination" container>
                      <Pagination
                        page={props.paginateOptions.currentPage}
                        count={Math.ceil(props.paginateOptions.totalItems / 10)}
                        color="secondary"
                        onChange={handleChangePage}
                      />
                    </Grid>
                  )}
              </>
            )}
          {props.status === "success" && props.rows.length === 0 && (
            <Grid className="no-results" container>
              <Grid container>
                <img
                  src="https://cocheras-online-public.s3.amazonaws.com/empty-states/emptyStateSearchNotFound.png"
                  width={300}
                  alt="No results"
                />
              </Grid>
              <Grid className="no-results-text" container>
                {props.noFoundMessage ? (
                  <>
                    <Grid container className="no-results-text-title">
                      <Typography variant="h1" color={primaryColor1}>
                        {props.noFoundMessage.title}
                      </Typography>
                    </Grid>
                    <Grid container className="no-results-text-subtitle">
                      <Typography
                        variant="paragraph-18"
                        text={props.noFoundMessage.description}
                      />
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid container className="no-results-text-title">
                      <Typography variant="h1" color={primaryColor1}>
                        No hay resultados aquí
                      </Typography>
                    </Grid>
                    <Grid container className="no-results-text-subtitle">
                      <Typography
                        variant="paragraph-18"
                        text={
                          <>
                            Lo sentimos, no encontramos ningún resultado que
                            coincida con tu búsqueda.
                            <br />
                            Asegúrate de que los términos estén escritos
                            correctamente y prueba nuevamente.
                          </>
                        }
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
          )}
        </TableContainer>
        {props.options?.length && openRowOptions && (
          <Popper
            placement="bottom-end"
            className="popper"
            id={id}
            open={openRowOptions}
            anchorEl={anchorElRowOptions}
          >
            <ClickAwayListener onClickAway={onClosePopper}>
              <Grid className="options">
                {props.options
                  ?.filter(
                    (o) =>
                      o.show === undefined ||
                      (typeof o.show === "boolean" && o.show) ||
                      (typeof o.show === "function" && o.show(indexSelected))
                  )
                  .map((option, i) => (
                    <ButtonLevel
                      align="left"
                      key={i}
                      disabled={
                        option.disabled === undefined
                          ? false
                          : typeof option.disabled === "boolean"
                            ? option.disabled
                            : option.disabled(indexSelected)
                      }
                      size="small"
                      variant="text"
                      color="primary"
                      title={option.label ? option.label : ""}
                      onClick={() => {
                        option.onClick(indexSelected);
                        setAnchorElRowOptions(null);
                      }}
                    />
                  ))}
              </Grid>
            </ClickAwayListener>
          </Popper>
        )}
        {props.headOptions?.length && openHeaderOptions && (
          <Popper
            placement="bottom-end"
            className="popper"
            id={id}
            open={openHeaderOptions}
            anchorEl={anchorElHeaderOptions}
          >
            <ClickAwayListener onClickAway={onClosePopper}>
              <Grid className="options">
                {props.headOptions
                  ?.filter(
                    (o) =>
                      o.show === undefined ||
                      (typeof o.show === "boolean" && o.show) ||
                      (typeof o.show === "function" && o.show(indexSelected))
                  )
                  .map((option, i) => (
                    <ButtonLevel
                      align="left"
                      key={i}
                      disabled={
                        option.disabled === undefined
                          ? false
                          : typeof option.disabled === "boolean"
                            ? option.disabled
                            : option.disabled(indexSelected)
                      }
                      size="small"
                      variant="text"
                      color="primary"
                      title={option.label ? option.label : ""}
                      onClick={() => {
                        option.onClick();
                        setAnchorElHeaderOptions(null);
                      }}
                    />
                  ))}
              </Grid>
            </ClickAwayListener>
          </Popper>
        )}
      </Grid>
    </Grid>
  );
}
