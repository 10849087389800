import { Grid } from "@mui/material";
import icons from "../../../../utils/icons";
import ButtonLevel from "../../Buttons";
import { primaryColor1, primaryColor2 } from "../../../../utils/VARIABLES";

interface Props {
  checked?: boolean;
  onClick?: () => void;
  color?: "primary" | "secondary";
  widthIcons?: number;
}
export default function Checkbox(props: Props) {
  const { onClick, checked = true, color = "primary" } = props;

  const getColor = () => {
    switch (color) {
      case "primary":
        return primaryColor1;
      case "secondary":
        return primaryColor2;
      default:
        return primaryColor1;
    }
  };

  return (
    <Grid className="checkbox">
      <ButtonLevel
        onClick={onClick}
        icon={{icon:icons.checkbox(checked, { fill: getColor(), width: props.widthIcons })}}
        size="small"
        align="left"
      />
    </Grid>
  );
}
