import { Grid } from "@mui/material"
import { DrawerStatus, SideDrawer } from "../../kit/SideDrawer";
import { useEffect, useState } from "react";
import NumberInput from "../../kit/Inputs/Number";
import SelectInput from "../../kit/Inputs/Select";
import { editProductAmountBulk, getAllProductBySupplierId, Product } from "../../../services/product";

import "./UpdateAmountsDrawer.scss"
import Typography from "../../kit/Typography";
import { defaultPaginatedResponse, PaginatedResponse } from "../../../utils/generalServiceResponses";
import Checkbox from "../../kit/Selectors/Checkbox";

interface Props {
  supplierId: number,
  closeDrawer: () => void,
  openDrawer: boolean
}
export function UpdateAmountsDrawer(props: Props) {
  const [drawerStatus, setDrawerStatus] = useState<DrawerStatus>("idle");
  const [productsToUpdateAmountDto, setProductsToUpdateAmountDto] = useState<{ products: Set<Product>, changeType: 1 | -1, percentageToChange: number }>({
    products: new Set(),
    changeType: 1,
    percentageToChange: 0,
  })

  const [productsBySupplierPaginated, setProductsBySupplierPaginated] = useState<
    PaginatedResponse<Product>
  >(defaultPaginatedResponse);


  const handleGetProductsBySupplier = () => {
    (async () => {
      const response = await getAllProductBySupplierId(
        undefined,
        props.supplierId
      );
      if (response.data !== null) {
        setProductsBySupplierPaginated(response);

        const productSet = new Set<Product>();
        response.data.forEach(product => productSet.add(product));
        setProductsToUpdateAmountDto({ ...productsToUpdateAmountDto, products: productSet });
      } else {
        setDrawerStatus("error")
      }
    })();
  };
  useEffect(handleGetProductsBySupplier, []);

  const handlePostUpdateAmountProductBulk = () => {
    (async () => {
      const productIds: number[] = [];
      productsToUpdateAmountDto.products.forEach(product => productIds.push(product.id))
      const response = await editProductAmountBulk({
        productIds,
        percentageToEdit: productsToUpdateAmountDto.percentageToChange,
        updateType: productsToUpdateAmountDto.changeType
      });
      if (response.status === 201) {
        setDrawerStatus("success");
      } else {
        setDrawerStatus("error");
      }
    })();
  }

  function removeOrAddItemFromDto(product: Product) {
    const productSet = new Set(productsToUpdateAmountDto.products);
    if (productSet.has(product)) {
      productSet.delete(product);
    } else {
      productSet.add(product);
    }
    setProductsToUpdateAmountDto({ ...productsToUpdateAmountDto, products: productSet });
  }

  const disable = !productsToUpdateAmountDto.products.size || productsToUpdateAmountDto.percentageToChange === 0;
  return (
    <SideDrawer
      title="Editar precios masivamente"
      saveOnclick={handlePostUpdateAmountProductBulk}
      saveLabel="Guardar"
      closeDrawer={props.closeDrawer}
      open={props.openDrawer}
      drawerStatus={drawerStatus}
      onRetry={() => setDrawerStatus("idle")}
      disabled={disable}
    >
      <Grid container className="update-amounts-drawer">
        <Grid container className="update-amounts-drawer-item">
          <SelectInput
            itemSelected={productsToUpdateAmountDto.changeType}
            onChange={(changeType) => setProductsToUpdateAmountDto({ ...productsToUpdateAmountDto, changeType })}
            label="Los productos"
            items={[{ value: 1, label: "Aumentan" }, { value: -1, label: "Bajan" }]}
          />
        </Grid>
        <Grid container className="update-amounts-drawer-item">
          <NumberInput
            variant="line"
            value={productsToUpdateAmountDto.percentageToChange}
            label={"Porcentaje de " + (productsToUpdateAmountDto.changeType === 1 ? 'aumento' : 'decremento')}
            onChange={(percentageToChange) => setProductsToUpdateAmountDto({ ...productsToUpdateAmountDto, percentageToChange })}
            allowDecimals
            thousandSeparator
          />
        </Grid>
        <Grid container className="update-amounts-drawer-item">
          <Typography type="bold">Productos</Typography>
        </Grid>
        <Grid container className="update-amounts-drawer-item">
          <Grid container className="products">
            {productsBySupplierPaginated.data.map((product: Product) => (
              <Grid key={product.id} container className="product-item" alignItems="center" spacing={5}>
                <Grid item xs={1}>
                  <Checkbox
                    checked={productsToUpdateAmountDto.products.has(product)}
                    onClick={() => removeOrAddItemFromDto(product)}
                  />
                </Grid>
                <Grid item xs={11}>
                  <Typography>{product.name}</Typography>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </SideDrawer>
  )
}