import { Grid, TextField } from "@mui/material";
import "./TextInput.scss";
import Typography from "../../Typography";
import { greenColor1, redColor1, yellowColor3 } from "../../../../utils/VARIABLES";

interface Props {
  value?: string | number | null;
  disabled?: boolean;
  label: string | React.ReactElement;
  placeholder?: string;
  onChange: (_value: string) => void;
  type?: "number";
  variant?: "outlined" | "filled" | "standard";
  isRequired?: boolean;
  helperText?: {
    text: string;
    type: "success" | "warning" | "error";
  };
}

export default function TextInput(props: Props) {
  const { value, disabled = false, variant = "outlined", isRequired = false } = props;

  return (
    <Grid container className="text-input">
      <Typography
        className={`text-input-label ${isRequired && "input-required"}`}
        text={props.label}
        variant="paragraph-14"
      />
      <Grid className="text-field" container>
        <TextField
          color="secondary"
          type={props.type}
          placeholder={props.placeholder}
          value={value}
          className={`text-input-material ${props.helperText && "with-helper-" + props.helperText.type}`}
          onChange={(event) => props.onChange(event.target.value)}
          variant={variant}
          disabled={disabled}
          InputLabelProps={{ shrink: false }}
        />
        {props.helperText && (
          <Grid container>
            <Typography
              className="helper-text"
              variant="paragraph-12"
              color={
                props.helperText?.type === "success"
                  ? greenColor1
                  : props.helperText?.type === "warning"
                    ? yellowColor3
                    : props.helperText?.type === "error"
                      ? redColor1
                      : ""
              }
            >
              {props.helperText?.text}
            </Typography>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}
