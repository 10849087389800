import { useSearchParams } from "react-router-dom";
import { Grid } from "@mui/material";
import SupplierTable from "../../components/Supplier/SupplierTable";
import SupplierDetail from "../../components/Supplier/SupplierDetail";

export default function Supplier() {
  const [searchParams] = useSearchParams();
  const supplierId = searchParams.get("id");

  return (
    <Grid container>
      {supplierId !== null ? (
        <SupplierDetail
          supplierId={parseInt(supplierId)}
        />
      ) : (
        <SupplierTable />
      )}
    </Grid>
  );
}
