import ButtonLevel from "../../Buttons";
import Typography from "../../Typography";
import "./CardTag.scss";
import { Grid } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { whiteColor } from "../../../../utils/VARIABLES";
interface Props {
  id: number;
  name: string;
  totalProducts: number;
}
export default function CardTag(props: Props) {
  return (
    <Grid
      className="card-tag-xs"
      container
    >
      <button className="card-tag-button" onClick={() => window.location.href = "?tid=" + props.id}>
        <Grid className="card" container>
          <Grid className="card-tag-content" container>
            <Grid className="card-tag-name" container>
              <Typography variant="h2" text={props.name} color={whiteColor} />
            </Grid>
            <Grid className="add-button" container>
              <AddIcon fontSize="large" />
            </Grid>
            <Grid className="card-tag-products" container>
              <Typography
                variant="paragraph-24"
                text={props.totalProducts + " Productos"}
                color={whiteColor}
              />
            </Grid>
          </Grid>
        </Grid>
      </button>
    </Grid>
  );
}
