import { Fade, Grid, Modal } from "@mui/material";

import "./FilterModal.scss";
import DateInput from "../../kit/Inputs/Date";
import { useState } from "react";
import ButtonLevel from "../../kit/Buttons";

interface Props {
  open: boolean;
  dateFrom: string | null;
  dateTo: string | null;
  closeModal: () => void;
  onConfirm: (dateFrom: string, dateTo: string) => void;
}

export default function FilterModal(props: Props) {
  const [dateFrom, setDateFrom] = useState<string>(
    props.dateFrom || new Date().toString()
  );
  const minDate = () => {
    const date = new Date(dateFrom);
    date.setDate(date.getDate() + 1);
    return date;
  };
  const [dateTo, setDateTo] = useState<string>(
    props.dateTo || minDate().toString()
  );

  const disabled = new Date(dateFrom) >= new Date(dateTo);

  return (
    <Grid container>
      <Modal open={props.open} onClose={props.closeModal} closeAfterTransition>
        <Fade in={props.open}>
          <Grid container className="account-status-filter-modal">
            <Grid container className="filter-modal-content">
              <Grid item xs className="filter-modal-content-date-from">
                <DateInput
                  label="Desde"
                  date={dateFrom}
                  onChange={(value: string) => setDateFrom(value)}
                  variant="calendar"
                />
              </Grid>
              <Grid item xs className="filter-modal-content-date-to">
                <DateInput
                  minDate={minDate()}
                  label="Hasta"
                  date={dateTo}
                  onChange={(value: string) => setDateTo(value)}
                  variant="calendar"
                />
              </Grid>
            </Grid>
            <Grid container className="button-confirmation-modal" spacing={2}>
              <Grid item xs={6}>
                <ButtonLevel
                  color="secondary"
                  title="Cancelar"
                  size="large"
                  onClick={props.closeModal}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={6}>
                <ButtonLevel
                  disabled={disabled}
                  color="primary"
                  variant="contained"
                  size="large"
                  onClick={() => {
                    props.onConfirm(dateFrom, dateTo);
                    props.closeModal();
                  }}
                  title="Filtrar"
                />
              </Grid>
            </Grid>
          </Grid>
        </Fade>
      </Modal>
    </Grid>
  );
}
