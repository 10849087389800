import { Grid } from "@mui/material";
import "./CreateCustomerDrawer.scss";
import { useState } from "react";
import { DrawerStatus, SideDrawer } from "../../kit/SideDrawer";
import { CustomerDTO, postNewCustomer } from "../../../services/customers";
import TextInput from "../../kit/Inputs/Text";
import NumberInput from "../../kit/Inputs/Number";

interface Props {
  setOpenDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  openDrawer: boolean;
  updatePage: () => void;
  title: string;
}

const defaultCustomerDTO = {
  firstName: "",
  lastName: "",
  document: "",
  address: "",
  email: "",
  phone: "",
};

export default function CreateCustomerDrawer(props: Props) {
  const [customerDTO, setCustomerDTO] =
    useState<CustomerDTO>(defaultCustomerDTO);
  const [drawerStatus, setDrawerStatus] = useState<DrawerStatus>("idle");

  const handlePostNewCustomer = () => {
    setDrawerStatus("loading");
    (async () => {
      let response = await postNewCustomer(customerDTO);

      if (response !== undefined) {
        props.updatePage();
        setDrawerStatus("success");
      } else {
        setDrawerStatus("error");
      }
    })();
  };

  const onCloseModal = () => {
    props.setOpenDrawer(false);
    setCustomerDTO(defaultCustomerDTO);
    setDrawerStatus('idle');
  };

  const disableCreateCustomer = () => {
    if (
      !customerDTO.firstName || !customerDTO.lastName || !customerDTO.document || customerDTO.document.length < 7
    ) return true;
    return false;
  };

  return (
    <SideDrawer
      drawerStatus={drawerStatus}
      onRetry={() => setDrawerStatus("idle")}
      title={props.title}
      disabled={disableCreateCustomer()}
      saveOnclick={handlePostNewCustomer}
      closeDrawer={onCloseModal}
      open={props.openDrawer}
      saveLabel="Guardar"
    >
      <Grid className="create-customer-drawer" container>
        <Grid container className="input-drawer">
          <TextInput
            isRequired
            value={customerDTO.firstName}
            placeholder="Nombre del cliente"
            onChange={(firstName) =>
              setCustomerDTO((prevState) => ({
                ...prevState,
                firstName,
              }))
            }
            label="Nombre"
          />
        </Grid>
        <Grid container className="input-drawer">
          <TextInput
            isRequired
            value={customerDTO.lastName}
            placeholder="Apellido del cliente"
            onChange={(lastName) =>
              setCustomerDTO((prevState) => ({
                ...prevState,
                lastName,
              }))
            }
            label="Apellido"
          />
        </Grid>
        <Grid container className="input-drawer">
          <NumberInput
            isRequired
            value={customerDTO.document}
            placeholder="Documento del cliente"
            onChange={(document) =>
              setCustomerDTO((prevState) => ({
                ...prevState,
                document: document.toString(),
              }))
            }
            label="Documento"
          />
        </Grid>
        <Grid container className="input-drawer">
          <TextInput
            value={customerDTO.address}
            placeholder="Dirección del cliente"
            onChange={(address) =>
              setCustomerDTO((prevState) => ({
                ...prevState,
                address,
              }))
            }
            label="Dirección"
          />
        </Grid>
        <Grid container className="input-drawer">
          <TextInput
            value={customerDTO.email}
            placeholder="Email del cliente"
            onChange={(email) =>
              setCustomerDTO((prevState) => ({
                ...prevState,
                email,
              }))
            }
            label="Email"
          />
        </Grid>
        <Grid container className="input-drawer">
          <TextInput
            value={customerDTO.phone}
            placeholder="Télefono del cliente"
            onChange={(phone) =>
              setCustomerDTO((prevState) => ({
                ...prevState,
                phone,
              }))
            }
            label="Télefono"
          />
        </Grid>
      </Grid>
    </SideDrawer>
  );
}
