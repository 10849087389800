import { useEffect, useState, useRef } from "react";
import { Grid } from "@mui/material";
import BasicTable from "../../kit/BasicTable";
import MenuTop from "../../MenuTop";
import "./Suppliers.scss";
import Alerts, { AlertType } from "../../kit/Alert";
import {
  PaginatedResponse,
  defaultPaginateOptions,
  defaultPaginatedResponse,
} from "../../../utils/generalServiceResponses";
import EmptyState from "../../kit/EmptyState/DesktopEmptyState";
import ConfirmationModal from "../../kit/Modal/ConfirmationModal";
import {
  deleteSupplier,
  getAllSuppliers,
  Supplier,
} from "../../../services/supplier";
import ButtonLevel from "../../kit/Buttons";
import CreateNewSupplierDrawer from "../CreateNewSupplierDrawer";
import UpdateSupplierDrawer from "../UpdatesupplierDrawer";

type TableFilter = {
  isAvailable: boolean;
};

export default function SupplierTable() {
  const [status, setStatus] = useState<"loading" | "success" | "error">(
    "loading"
  );
  const [paginateOptions, setPaginateOptions] = useState(
    defaultPaginateOptions
  );
  const [supplierPaginated, setSupplierPaginated] = useState<
    PaginatedResponse<Supplier>
  >(defaultPaginatedResponse);

  const [openNewSupplierDrawer, setOpenNewSupplierDrawer] = useState(false);
  const [supplierToDeleteModal, setSupplierToDeleteModal] = useState(0);
  const [supplierToUpdate, setSupplierToUpdate] = useState<Supplier | null>(
    null
  );

  const [alertStatus, setAlertStatus] = useState<AlertType>("close");

  const [alertStatusConfirmation, setAlertStatusConfirmation] =
    useState<AlertType>("close");
  const [alertEdit, setAlertEdit] = useState<AlertType>("close");

  const [tableFilter, setTableFilter] = useState<TableFilter>({
    isAvailable: true,
  });

  const handleSupplierData = () => {
    setStatus("loading");
    (async () => {
      const data = await getAllSuppliers(
        paginateOptions,
        tableFilter.isAvailable
      );
      if (data !== undefined) {
        setSupplierPaginated(data);
        setStatus("success");
      } else {
        setStatus("error");
      }
    })();
  };

  const timeoutIdRef = useRef<NodeJS.Timeout | null>(null);
  useEffect(() => {
    // clear the timeout and prevent the callback from being called
    if (timeoutIdRef.current !== null) clearTimeout(timeoutIdRef.current);

    timeoutIdRef.current = setTimeout(handleSupplierData, 300);
  }, [
    tableFilter.isAvailable,
    paginateOptions.search,
    paginateOptions.page,
    paginateOptions.limit,
  ]);

  const handleDeletedSupplier = (id: number) => {
    (async () => {
      const data = await deleteSupplier(id);
      if (data !== undefined) {
        handleSupplierData();
        setAlertStatusConfirmation("success");
      } else {
        setAlertStatusConfirmation("error");
      }
      setSupplierToDeleteModal(0);
    })();
  };
  const isEmptyState = () =>
    status === "success" &&
    supplierPaginated.data.length === 0 &&
    paginateOptions.search === "";

  return (
    <Grid container className="supplier-dashboard">
      {/* {isEmptyState() && (
        <Grid container className="supplier-dashboard-emptyState">
          <EmptyState
            text="Crea tu primer proveedor"
            subtitle="Registra todos tus provedores para poder visualizarlos en una tabla ordenada y optimizar tu experiencia al gestionarlos."
            titleButton="Comenzar"
            onClick={() => setOpenNewSupplierDrawer(true)}
          />
        </Grid>
      )} */}
      <Grid container className="supplier-dashboard-content">
        <BasicTable
          rightElement={
            <ButtonLevel
              fillWidth={false}
              onClick={() => setOpenNewSupplierDrawer(true)}
              title="Agregá un proveedor"
            />
          }
          status={status}
          head="Proveedores"
          search={{
            value: paginateOptions.search,
            onChange: (value) => {
              if (value === "") setStatus("loading");
              setPaginateOptions({ ...paginateOptions, search: value });
            },
            placeholder: "Buscar por proveedor",
          }}
          options={[
            {
              label: "Editar ",
              onClick: (rowIndex: number) => {
                const supplier = supplierPaginated.data[rowIndex];
                setSupplierToUpdate(supplier);
              },
            },
            {
              label: "Eliminar ",
              onClick: (rowIndex: number) => {
                const supplier = supplierPaginated.data[rowIndex];
                setSupplierToDeleteModal(supplier.id);
              },
            },
          ]}
          columns={["Nombre", "Télefono", "Email", ""]}
          rows={supplierPaginated.data.map((supplier) => {
            return [
              supplier?.name,
              supplier?.phone,
              supplier?.email,
              <ButtonLevel
                color="secondary"
                variant="text"
                title="Ver detalles"
                align="right"
                onClick={() => {
                  window.location.href = `?id=${supplier.id}`;
                }}
              />,
            ];
          })}
          paginateOptions={{
            currentPage: paginateOptions.page,
            rowsPerPage: paginateOptions.limit,
            setCurrentPage: (page: number) => {
              setPaginateOptions({ ...paginateOptions, page });
            },
            totalItems: supplierPaginated.totalItems,
          }}
        />
      </Grid>
      <CreateNewSupplierDrawer
        updatePage={handleSupplierData}
        openDrawer={openNewSupplierDrawer}
        setOpenDrawer={setOpenNewSupplierDrawer}
      />
      <ConfirmationModal
        description="Este proveedor se eliminara permanentemente y se borrara todos los productos.
        ¿Está seguro que desea continuar?"
        title="Eliminar"
        closeModal={() => setSupplierToDeleteModal(0)}
        open={supplierToDeleteModal ? true : false}
        confirmationOption={{
          onclick: () => handleDeletedSupplier(supplierToDeleteModal),

          title: "Eliminar",
          color: "error",
        }}
      />
      {!!supplierToUpdate && (
        <UpdateSupplierDrawer
          supplier={supplierToUpdate}
          title="Editar datos del proveedor"
          setAlertStatus={setAlertEdit}
          updatePage={handleSupplierData}
          openModal={!supplierToUpdate ? false : true}
          setOpenModal={setSupplierToUpdate}
        />
      )}
      {alertStatus !== "close" && (
        <Alerts
          setAlertStatus={setAlertStatus}
          severity={alertStatus}
          message={
            alertStatus === "success"
              ? "El proveedor se a creado con exíto"
              : "Ocurrío un error"
          }
        />
      )}
      {alertStatusConfirmation !== "close" && (
        <Alerts
          setAlertStatus={setAlertStatusConfirmation}
          severity={alertStatusConfirmation}
          message={
            alertStatusConfirmation === "success"
              ? "El proveedor se a eliminado con exíto"
              : "Ocurrío un error al eliminar el proveedor"
          }
        />
      )}
      {alertEdit !== "close" && (
        <Alerts
          setAlertStatus={setAlertEdit}
          severity={alertEdit}
          message={
            alertEdit === "success"
              ? "El proveedor se a editado con exíto"
              : "Ocurrío un error al editar el proveedor"
          }
        />
      )}
    </Grid>
  );
}
