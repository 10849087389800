import { Grid, IconButton, InputAdornment, TextField } from "@mui/material";
import "./Password.scss";
import Typography from "../../Typography";
import { greenColor1, redColor1, yellowColor3 } from "../../../../utils/VARIABLES";
import { useState } from "react";
import icons from "../../../../utils/icons";

interface Props {
  label: string;
  onChange: (_value: string) => void;
  placeholder?: string;
  helperText?: {
    text: string;
    type: "success" | "warning" | "error";
  };
}
export default function InputPassword(props: Props) {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Grid container className="password">
      <Typography text={props.label} variant="paragraph-14" />
      <Grid className="text-field" container>
        <TextField
          className={`password-input-material ${props.helperText && "with-helper-" + props.helperText.type}`}
          placeholder={props.placeholder}
          color="secondary"
          onChange={(event) => props.onChange(event.target.value)}
          id="outlined-password-input"
          type={showPassword ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  edge="end"
                  size="small"
                >
                  {showPassword ? icons.eyeShow() : icons.eyeHide()}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Grid container>
          <Typography
            className="helper-text"
            variant="paragraph-12"
            color={
              props.helperText?.type === "success"
                ? greenColor1
                : props.helperText?.type === "warning"
                  ? yellowColor3
                  : props.helperText?.type === "error"
                    ? redColor1
                    : ""
            }
          >
            {props.helperText?.text}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}
