import { Button, Grid } from "@mui/material";
import "./CreateNewMovementDrawer.scss";
import { useState } from "react";
import { DrawerStatus, SideDrawer } from "../../kit/SideDrawer";
import { AlertType } from "../../kit/Alert";
import { MovementDTO, postNewMovement } from "../../../services/movements";
import moment from "moment";
import DateInput from "../../kit/Inputs/Date";
import NumberInput from "../../kit/Inputs/Number";
import MultilineInput from "../../kit/Inputs/Multiline";
import SelectInput from "../../kit/Inputs/Select";
import icons from "../../../utils/icons";
import { grayColor, primaryColor1 } from "../../../utils/VARIABLES";
import Typography from "../../kit/Typography";

interface Props {
  setAlertStatus: React.Dispatch<React.SetStateAction<AlertType>>;
  setOpenDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  openDrawer: boolean;
  updatePage: () => void;
  title: string;
}

const defaultMovementDTO = {
  amount: 0,
  description: "",
  date: "",
  amountType: 1,
  paymentMethod: "",
};

export default function CreateNewMovementDrawer(props: Props) {
  const [date, setDate] = useState<string>(moment().format("YYYY-MM-DD HH:mm"));
  const [movementDTO, setMovementDTO] =
    useState<MovementDTO>(defaultMovementDTO);
  const [drawerStatus, setDrawerStatus] = useState<DrawerStatus>("idle");

  const handlePostNewMovement = () => {
    setDrawerStatus("loading");
    (async () => {
      movementDTO.amount = movementDTO.amount * movementDTO.amountType;
      let response = await postNewMovement(movementDTO);

      if (response !== undefined) {
        props.updatePage();
        setDrawerStatus("success");
      } else {
        setDrawerStatus("error");
      }
    })();
  };

  const onCloseModal = () => {
    props.setOpenDrawer(false);
    setMovementDTO(defaultMovementDTO);
  };

  const disableGarageDTO = () => {
    if (
      !movementDTO.amount ||
      !movementDTO.paymentMethod ||
      drawerStatus === "loading"
    ) return true;
    return false;
  };

  return (
    <SideDrawer
      drawerStatus={drawerStatus}
      onRetry={() => setDrawerStatus("idle")}
      title={props.title}
      disabled={disableGarageDTO()}
      saveOnclick={handlePostNewMovement}
      closeDrawer={onCloseModal}
      open={props.openDrawer}
      saveLabel="Guardar"
    >
      <Grid className="create-movement-drawer" container>
        <Grid className="date-movement-drawer input-drawer" container>
          <DateInput
            label={<span className="required-label">Fecha y hora </span>}
            date={date}
            onChange={setDate}
            variant="calandarWithHours"
          />
        </Grid>
        <Grid className="input-drawer" container>
          <SelectInput
            itemSelected={movementDTO.amountType}
            label={<span className="required-label">Tipo de movimiento </span>}
            onChange={(amountType: -1 | 1) =>
              setMovementDTO((prevState) => ({
                ...prevState,
                amountType,
              }))
            }
            items={[
              {
                value: -1,
                label: "Egreso",
              },
              {
                value: 1,
                label: "Ingreso",
              },
            ]}
          />
        </Grid>
        <Grid container className="form_input">
          <NumberInput
            value={movementDTO.amount}
            placeholder="Agregar monto..."
            onChange={(amount) =>
              setMovementDTO((prevState) => ({
                ...prevState,
                amount,
              }))
            }
            label={<span className="required-label">Monto </span>}
          />
        </Grid>
        <Grid container className="input-drawer">
          <Typography>
            {<span className="required-label">Método de pago </span>}
          </Typography>
        </Grid>
        <Grid className="box-payment-methods" container>
          <Grid item xs={4}>
            <Button
              style={{
                borderColor:
                  movementDTO?.paymentMethod === "Cash"
                    ? primaryColor1
                    : undefined,
              }}
              onClick={() =>
                setMovementDTO((prevState) => ({
                  ...prevState,
                  paymentMethod: "Cash",
                }))
              }
              className="payment-method payment-method-movement"
            >
              <Grid
                className="payment-method-button"
                onClick={() =>
                  setMovementDTO((prevState) => ({
                    ...prevState,
                    paymentMethod: "Transfer",
                  }))
                }
                container
              >
                <Grid
                  className={`payment-icon ${movementDTO?.paymentMethod}`}
                  container
                >
                  {icons.simpleCash({
                    width: 28,
                    height: 28,
                    fill:
                      movementDTO?.paymentMethod === "Cash"
                        ? primaryColor1
                        : undefined,
                  })}
                </Grid>
                <Grid className="payment-title" container>
                  <Typography
                    color={
                      movementDTO?.paymentMethod === "Cash"
                        ? primaryColor1
                        : undefined
                    }
                    variant="paragraph-10"
                  >
                    Efectivo
                  </Typography>
                </Grid>
              </Grid>
            </Button>
          </Grid>
          <Grid className="payment-methods-transfer" item xs={4}>
            <Button
              style={{
                borderColor:
                  movementDTO?.paymentMethod === "Transfer"
                    ? primaryColor1
                    : undefined,
              }}
              onClick={() =>
                setMovementDTO((prevState) => ({
                  ...prevState,
                  paymentMethod: "Transfer",
                }))
              }
              className="payment-method payment-method-movement"
            >
              <Grid className={`payment-method-button`} container>
                <Grid className="payment-icon" container>
                  {icons.bank({
                    fill:
                      movementDTO?.paymentMethod === "Transfer"
                        ? primaryColor1
                        : grayColor,
                  })}
                </Grid>
                <Grid className="payment-title" container>
                  <Typography
                    color={
                      movementDTO?.paymentMethod === "Transfer"
                        ? primaryColor1
                        : grayColor
                    }
                    variant="paragraph-10"
                  >
                    Transfer.
                  </Typography>
                </Grid>
              </Grid>
            </Button>
          </Grid>
          <Grid className="payment-methods-card" item xs={4}>
            <Button
              style={{
                borderColor:
                  movementDTO?.paymentMethod === "Card"
                    ? primaryColor1
                    : grayColor,
              }}
              onClick={() =>
                setMovementDTO((prevState) => ({
                  ...prevState,
                  paymentMethod: "Card",
                }))
              }
              className="payment-method payment-method-movement"
            >
              <Grid className="payment-method-button" container>
                <Grid className="payment-icon" container>
                  {icons.card({
                    width: 24,
                    height: 24,
                    color:
                      movementDTO?.paymentMethod === "Card"
                        ? primaryColor1
                        : grayColor,
                  })}
                </Grid>
                <Grid className="payment-title" container>
                  <Typography
                    color={
                      movementDTO?.paymentMethod === "Card"
                        ? primaryColor1
                        : grayColor
                    }
                    variant="paragraph-10"
                  >
                    Débito
                  </Typography>
                </Grid>
              </Grid>
            </Button>
          </Grid>
        </Grid>
        <Grid className="input-drawer note" container>
          <MultilineInput
            rows={4}
            label="Observación"
            onChange={(description) =>
              setMovementDTO((prevState) => ({
                ...prevState,
                description,
              }))
            }
            placeholder="Agregar observación"
          />
        </Grid>
      </Grid>
    </SideDrawer>
  );
}
