import { Grid } from "@mui/material";
import MenuTop from "../../MenuTop";
import "./Card.scss";
import { useEffect, useState } from "react";
import { getAllTag, Tag } from "../../../services/tags";
import ButtonLevel from "../../kit/Buttons";
import CardTag from "../../kit/Cards/CardTag";
import { TagDrawerOptions } from "../TagDrawerOptions";



export default function CardTags() {
  const [tags, setTags] = useState<Tag[]>([]);
  const [openTagDrawerOptions, setOpenTagDrawerOptions] = useState(false);

  const handleGetTags = () => {
    (async () => {
      let response = await getAllTag();
      if (response.status === 200) {
        setTags(response.data?.tags || []);
      }
    })();
  };

  useEffect(() => {
    handleGetTags();
  }, []);

  return (
    <Grid className="tags" container>
      <Grid container>
        <MenuTop
          rightElement={
            <Grid container className="top-buttons">
              <ButtonLevel title="Opciones" onClick={() => setOpenTagDrawerOptions(true)} />
            </Grid>
          }
          title="Productos"
        />
      </Grid>
      <Grid
        container
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fill, minmax(18rem, 1fr))",
          gap: "3rem"
        }}
        className="cards-tags"
      >
        {tags.map((tag: Tag) => (
          <Grid key={tag.id} className="card-tag" container>
            <CardTag id={tag.id} name={tag.name} totalProducts={tag.totalProducts} />
          </Grid>
        ))}
      </Grid>
      <TagDrawerOptions
        tags={tags}
        setOpenDrawer={setOpenTagDrawerOptions}
        openDrawer={openTagDrawerOptions}
        updatePage={handleGetTags}
      />
    </Grid>
  );
}
