import React from "react";
import { Grid } from "@mui/material";
import Slider, { Settings } from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import icons from "../../../utils/icons";
import "./Carousel.scss";
import { primaryColor1, redColor1 } from "../../../utils/VARIABLES";


interface Props {
  maxColumns?: number;
  cardContent?: React.ReactElement[];
}
interface ArrowProps {
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}
export function Carousel(props: Props) {
  const { maxColumns = 1, cardContent = [] } = props;

  const CustomPrevArrow: React.FC<ArrowProps> = ({ onClick }) => (
    <div
      className="slick-prev"
      onClick={onClick}
    >
      {icons.previousArrow({ color: primaryColor1, width: 20, height: 20 })}
    </div>
  );

  const CustomNextArrow: React.FC<ArrowProps> = ({ onClick }) => (
    <div
      className="slick-next"
      onClick={onClick}
    >
      {icons.nextArrow({ color: primaryColor1, width: 20, height: 20 })}
    </div>
  );

  const settings: Settings = {
    speed: 700,
    slidesToShow: Math.min(maxColumns, cardContent.length),
    slidesToScroll: 1,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
  };

  const slider = React.useRef<Slider>(null);

  return (
    <Grid container className="carousel">
      <Slider {...settings} ref={slider}>
        {cardContent.map((item, index) => (
          <Grid key={index} item>
            <Grid container className="carousel-card" alignContent="center">
              {item}
            </Grid>
          </Grid>
        ))}
      </Slider>
    </Grid>
  );
}
