import { Button, Grid } from "@mui/material";
import { useState } from "react";
import { DrawerStatus, SideDrawer } from "../../kit/SideDrawer";
import { AlertType } from "../../kit/Alert";
import {
  Movement,
  updateMovement,
  UpdateMovementDTO,
} from "../../../services/movements";
import MultilineInput from "../../kit/Inputs/Multiline";
import NumberInput from "../../kit/Inputs/Number";
import SelectInput from "../../kit/Inputs/Select";
import DateInput from "../../kit/Inputs/Date";
import "./UpdateMovementDrawer.scss";
import icons from "../../../utils/icons";
import Typography from "../../kit/Typography";
import { grayColor, primaryColor1 } from "../../../utils/VARIABLES";

interface Props {
  movement: Movement;
  setAlertStatus: React.Dispatch<React.SetStateAction<AlertType>>;
  setOpenDrawer: React.Dispatch<React.SetStateAction<Movement | null>>;
  openDrawer: boolean;
  updatePage: () => void;
}

export default function UpdateMovementDrawer(props: Props) {
  const [drawerStatus, setDrawerStatus] = useState<DrawerStatus>("idle");

  const [updateMovementDTO, setUpdateMovementDTO] = useState<UpdateMovementDTO>(
    {
      id: props.movement.id,
      amount: props.movement?.amount,
      date: props.movement?.date,
      description: props.movement?.description,
      amountType: props.movement.amount > 0 ? 1 : -1,
      paymentMethod: props.movement.paymentMethod,
    }
  );

  const handleUpdateMovement = () => {
    (async () => {
      updateMovementDTO.amount *= updateMovementDTO.amountType;
      let response = await updateMovement(updateMovementDTO);
      if (response !== undefined) {
        props.updatePage();
        setDrawerStatus("success");
      } else {
        setDrawerStatus("error");
      }
    })();
  };

  const disableGarageDTO = () => {
    if (updateMovementDTO.date === "") return true;

    return false;
  };
  const onCloseModal = () => {
    props.setOpenDrawer(null);
  };

  return (
    <Grid className="update-movement-drawer">
      <SideDrawer
        drawerStatus={drawerStatus}
        title="Editar datos de movimiento"
        disabled={disableGarageDTO()}
        saveOnclick={handleUpdateMovement}
        closeDrawer={onCloseModal}
        open={props.openDrawer}
      >
        <Grid className="update-movement-form">
          <Grid className="form-input" container>
            <Grid className="date-movement-drawer" container>
              <DateInput
                label="Fecha y hora"
                date={updateMovementDTO.date}
                onChange={(date) =>
                  setUpdateMovementDTO((prevState) => ({
                    ...prevState,
                    date,
                  }))
                }
                variant="calandarWithHours"
              />
            </Grid>
            <Grid className="input-drawer" container>
              <SelectInput
                itemSelected={updateMovementDTO.amountType}
                label="Tipo de movimiento"
                onChange={(amountType: -1 | 1) =>
                  setUpdateMovementDTO((prevState) => ({
                    ...prevState,
                    amountType,
                  }))
                }
                items={[
                  {
                    value: -1,
                    label: "Egreso",
                  },
                  {
                    value: 1,
                    label: "Ingreso",
                  },
                ]}
              />
            </Grid>
            <Grid
              className="box-payment-methods box-payment-methods-movement"
              container
            >
              <Grid item xs={4}>
                <Button
                  style={{
                    borderColor:
                      updateMovementDTO?.paymentMethod === "Cash"
                        ? primaryColor1
                        : undefined,
                  }}
                  onClick={() =>
                    setUpdateMovementDTO((prevState) => ({
                      ...prevState,
                      paymentMethod: "Cash",
                    }))
                  }
                  className="payment-method payment-method-movement"
                >
                  <Grid
                    className="payment-method-button"
                    onClick={() =>
                      setUpdateMovementDTO((prevState) => ({
                        ...prevState,
                        paymentMethod: "Transfer",
                      }))
                    }
                    container
                  >
                    <Grid
                      className={`payment-icon ${updateMovementDTO?.paymentMethod}`}
                      container
                    >
                      {icons.simpleCash({
                        width: 28,
                        height: 28,
                        fill:
                          updateMovementDTO?.paymentMethod === "Cash"
                            ? primaryColor1
                            : undefined,
                      })}
                    </Grid>
                    <Grid className="payment-title" container>
                      <Typography
                        color={
                          updateMovementDTO?.paymentMethod === "Cash"
                            ? primaryColor1
                            : undefined
                        }
                        variant="paragraph-10"
                      >
                        Efectivo
                      </Typography>
                    </Grid>
                  </Grid>
                </Button>
              </Grid>
              <Grid className="payment-methods-transfer" item xs={4}>
                <Button
                  style={{
                    borderColor:
                      updateMovementDTO?.paymentMethod === "Transfer"
                        ? primaryColor1
                        : undefined,
                  }}
                  onClick={() =>
                    setUpdateMovementDTO((prevState) => ({
                      ...prevState,
                      paymentMethod: "Transfer",
                    }))
                  }
                  className="payment-method payment-method-movement"
                >
                  <Grid className={`payment-method-button`} container>
                    <Grid className="payment-icon" container>
                      {icons.bank({
                        fill:
                          updateMovementDTO?.paymentMethod === "Transfer"
                            ? primaryColor1
                            : grayColor,
                      })}
                    </Grid>
                    <Grid className="payment-title" container>
                      <Typography
                        color={
                          updateMovementDTO?.paymentMethod === "Transfer"
                            ? primaryColor1
                            : grayColor
                        }
                        variant="paragraph-10"
                      >
                        Transfer.
                      </Typography>
                    </Grid>
                  </Grid>
                </Button>
              </Grid>
              <Grid className="payment-methods-card" item xs={4}>
                <Button
                  style={{
                    borderColor:
                      updateMovementDTO?.paymentMethod === "Card"
                        ? primaryColor1
                        : grayColor,
                  }}
                  onClick={() =>
                    setUpdateMovementDTO((prevState) => ({
                      ...prevState,
                      paymentMethod: "Card",
                    }))
                  }
                  className="payment-method payment-method-movement"
                >
                  <Grid className="payment-method-button" container>
                    <Grid className="payment-icon" container>
                      {icons.card({
                        width: 24,
                        height: 24,
                        color:
                          updateMovementDTO?.paymentMethod === "Card"
                            ? primaryColor1
                            : grayColor,
                      })}
                    </Grid>
                    <Grid className="payment-title" container>
                      <Typography
                        color={
                          updateMovementDTO?.paymentMethod === "Card"
                            ? primaryColor1
                            : grayColor
                        }
                        variant="paragraph-10"
                      >
                        Débito
                      </Typography>
                    </Grid>
                  </Grid>
                </Button>
              </Grid>
            </Grid>
            <Grid container className="input-drawer">
              <NumberInput
                value={Math.abs(updateMovementDTO.amount)}
                placeholder="Agregar monto..."
                onChange={(amount) =>
                  setUpdateMovementDTO((prevState) => ({
                    ...prevState,
                    amount,
                  }))
                }
                label="Monto"
              />
            </Grid>
            <Grid className="input-drawer note" container>
              <MultilineInput
                value={updateMovementDTO.description}
                rows={4}
                label="Observación"
                onChange={(description) =>
                  setUpdateMovementDTO((prevState) => ({
                    ...prevState,
                    description,
                  }))
                }
                placeholder="Agregar observación"
              />
            </Grid>
          </Grid>
        </Grid>
      </SideDrawer>
    </Grid>
  );
}
