import { Alert as AlertMaterial, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import "./Alert.scss";
import Typography from "../Typography";
import icons from "../../../utils/icons";
import { backgroundColor2, greenColor2, redColor2 } from "../../../utils/VARIABLES";
import ButtonLevel from "../Buttons";

export type AlertType = "success" | "default" | "error" | "close";

interface Props {
  setAlertStatus: React.Dispatch<React.SetStateAction<AlertType>>;
  severity: AlertType;
  message: string;
  type?: "close" | "undo";
  action?: () => void;
}

export default function Alert(props: Props) {
  const { type = "close", action, severity = "default" } = props;

  const [show, setShow] = useState(true);

  useEffect(() => {
    const timeId = setTimeout(() => {
      setShow(false);
      props.setAlertStatus("close");
    }, 3000);

    return () => {
      clearTimeout(timeId);
    };
  }, []);

  const getIcon = () => {
    switch (severity) {
      case "success":
        return icons.indicators("success", {
          fill: backgroundColor2,
          color: greenColor2,
          width: 16,
          height: 16,
        });
      case "error":
        return icons.indicators("error", {
          fill: backgroundColor2,
          color: redColor2,
          width: 16,
          height: 16,
        });
      default:
        return false;
    }
  };

  return (
    <Grid className="alert">
      {show && (
        <Grid container>
          <AlertMaterial
            icon={getIcon()}
            variant="filled"
            className={`alert-material ${"alert-type-" + severity}`}
          >
            <Grid container className="alert-content">
              <Grid item xs={8}>
                <Typography variant="paragraph-14" text={props.message} />
              </Grid>
              {type === "undo" && (
                <Grid item xs={4} className="alert-content-action">
                  <ButtonLevel
                    title="Deshacer"
                    icon={{icon:icons.undo()}}
                    onClick={action}
                    variant="text"
                  />
                </Grid>
              )}
              {type === "close" && (
                <Grid item xs={4} className="alert-content-action">
                  <ButtonLevel
                    align="right"
                    icon={{icon:icons.close({ color: backgroundColor2 })}}
                    onClick={() => setShow(false)}
                    variant="text"
                  />
                </Grid>
              )}
            </Grid>
          </AlertMaterial>
        </Grid>
      )}
    </Grid>
  );
}
