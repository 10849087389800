import { Grid } from "@mui/material";
import Button from "@mui/material/Button";
import React from "react";
import "./Buttons.scss";
import Typography from "../Typography";

export type ButtonLevelColor = "primary" | "secondary" | "white" | "disabled" | "error" | "none";

interface Props {
  color?: ButtonLevelColor;
  disabled?: boolean;
  title?: string | React.ReactElement;
  onClick?: () => void;
  variant?: "text" | "outlined" | "contained";
  href?: string;
  size?: "small" | "medium" | "large";
  icon?: {
    position?: "start" | "end";
    icon: React.ReactElement;
  }
  align?: "center" | "left" | "right";
  fillWidth?: boolean;
  maxWidth?: boolean;
  onChangeTrue?: () => void;
}

export default function ButtonLevel(props: Props) {
  const {
    variant = "contained",
    title,
    onClick,
    href,
    size = "medium",
    align = "center",
    fillWidth = true,
    color = props.icon ? "none" : props.disabled ? "disabled" : "primary",
  } = props;

  let propIcon = props.icon ? {
    icon: props.icon.icon,
    position: props.icon.position ? props.icon.position : "start",
  } : null;

  return (
    <Grid
      className={`button-level ${fillWidth ? "button-level-fill-width" : "button-level-max-width"
        } button-level-color-${color} button-variant-${variant} button-align-${align} ${props.disabled ? "disabled" : ""
        }`}
      container
    >
      {!title ? (
        <>
          <Button
            disabled={props.disabled}
            onClick={onClick}
            href={!href ? "" : href}
            size={size}
            target={href ? "" : ""}
          >
            <span
              style={{
                display: "flex",
                alignItems: "center",
                transform: "scale(1.75)",
              }}
            >
              {propIcon && propIcon.position === "start" && propIcon.icon}
            </span>
            {title}
            <span
              style={{
                display: "flex",
                alignItems: "center",
                transform: "scale(1.75)",
              }}
            >
              {propIcon && propIcon.position === "end" && propIcon.icon}
            </span>
          </Button>
        </>
      ) : (
        <>
          {variant === "text" && (
            <Grid container className="text">
              <Button
                onChange={props.onChangeTrue}
                className={`text-button-${color}`}
                startIcon={propIcon && propIcon.position === "start" ? propIcon.icon : null}
                endIcon={propIcon && propIcon.position === "end" ? propIcon.icon : null}
                disabled={props.disabled}
                onClick={onClick}
                href={!href ? "" : href}
                size={size}
                variant="text"
              >
                <Typography
                  variant="paragraph-14"
                  type="semibold"
                  text={title}
                />
              </Button>
            </Grid>
          )}
          {variant === "outlined" && (
            <Grid container className="outlined">
              <Button
                onChange={props.onChangeTrue}
                className={`outlined-button-${color}`}
                startIcon={propIcon && propIcon.position === "start" ? propIcon.icon : null}
                endIcon={propIcon && propIcon.position === "end" ? propIcon.icon : null}
                disabled={props.disabled}
                onClick={onClick}
                href={!href ? "" : href}
                size={size}
                variant="outlined"
              >
                <Typography
                  text={title}
                  variant="paragraph-14"
                  type="semibold"
                />
              </Button>
            </Grid>
          )}
          {variant === "contained" && (
            <Grid container className="contained">
              <Button
                onChange={props.onChangeTrue}
                className={`contained-button-${color}`}
                startIcon={propIcon && propIcon.position === "start" ? propIcon.icon : null}
                endIcon={propIcon && propIcon.position === "end" ? propIcon.icon : null}
                disabled={props.disabled}
                onClick={onClick}
                href={!href ? "" : href}
                target="_blank"
                size={size}
                variant="contained"
              >
                <Typography
                  text={title}
                  variant="paragraph-14"
                  type="semibold"
                />
              </Button>
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
}
