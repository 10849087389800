import axios from "axios";
import { API_URL } from "../../config/env";
import { getToken } from "../utils/token";
import {
  PaginateOptions,
  defaultPaginateOptions,
} from "../../utils/generalServiceResponses";
import { PaginatedResponse } from "../../utils/generalServiceResponses";
import { defaultPaginatedResponse } from "../../utils/generalServiceResponses";
import { Checkout } from "../checkout";
import { PaymentMethod } from "../cart";

export type Movement = {
  amount: number;
  description: string;
  date: string;
  id: number;
  paymentMethod: PaymentMethod;
  checkout: Checkout;
};

export function movementPaymentMethoDTOEs(paymentMethod: PaymentMethod): string {
  switch (paymentMethod) {
    case 'Cash':
      return "Efectivo";
    case "Card":
      return "Tarjeta";
    case "Transfer":
      return "Transferencia";
    default:
      return "Efectivo";
  }
}

export async function getAllMovements(
  paginateOptions?: PaginateOptions,
  isAvailable?: boolean
): Promise<PaginatedResponse<Movement>> {
  if (paginateOptions === undefined)
    paginateOptions = { ...defaultPaginateOptions, limit: 100 };

  const token = await getToken();

  try {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios.get(API_URL + "/movement", {
      headers,
      params: {
        search: paginateOptions.search,
        page: paginateOptions.page,
        limit: paginateOptions.limit,
        isAvailable,
      },
    });
    return response.data;
  } catch (e) {
    console.warn(e);
    return defaultPaginatedResponse;
  }
}
//create new movement
export type MovementDTO = {
  amount: number;
  description: string;
  date: string;
  amountType: number;
  paymentMethod: string;
};
export async function postNewMovement(DTO: MovementDTO) {
  const token = await getToken();

  try {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios.post(API_URL + "/movement", DTO, { headers });
    return response.data;
  } catch (e) {
    console.warn(e);
  }
}
//delete movement
export async function deleteMovement(id: number) {
  const token = await getToken();
  try {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios.delete(API_URL + "/movement/" + id, {
      headers,
    });
    return response.data;
  } catch (e) {
    console.warn(e);
  }
}
//update movement DTO
export type UpdateMovementDTO = {
  id: number;
  amount: number;
  description: string;
  date: string;
  amountType: number;
  paymentMethod: string;
};
export async function updateMovement(DTO: UpdateMovementDTO) {
  const token = await getToken();

  try {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios.patch(API_URL + "/movement", DTO, {
      headers,
    });
    return response.data;
  } catch (e) {
    console.warn(e);
  }
}

export async function getMovementsBuffer(): Promise<{
  type: string;
  data: number[];
} | null> {
  const token = await getToken();
  try {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios.get(API_URL + "/movement/export/excel", {
      headers,
    });
    return response.data;
  } catch (e) {
    console.warn(e);
    return null;
  }
}

export async function getMovementsGroupedByDateBuffer(): Promise<{
  type: string;
  data: number[];
} | null> {
  const token = await getToken();
  try {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios.get(
      API_URL + "/movement/export/excel-grouped-by-date",
      {
        headers,
      }
    );
    return response.data;
  } catch (e) {
    console.warn(e);
    return null;
  }
}

export async function getMovementAmount(): Promise<{
  amount: number;
  outcome: number;
  income: number;
} | null> {
  const token = await getToken();
  try {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios.get(
      API_URL +
      `/movement/total-incomes-and-outcomes?year=${new Date().getFullYear()}`,
      {
        headers,
      }
    );
    return response.data;
  } catch (e) {
    console.warn(e);
    return null;
  }
}
