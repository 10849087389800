import { Box, Fade, Grid, Modal } from "@mui/material";
import Typography from "../kit/Typography";
import "./ModalTermsAndConditions.scss";
import ButtonLevel from "../kit/Buttons";
import { UserContext, acceptTyC } from "../../services/user";
import { useContext } from "react";

interface Props {
  disabled?: boolean;
  open: boolean;
  closeModal: () => void;
  saveOnclick?: () => void;
  saveLabel?: string;
}

export default function ModalTermsAndConditions(props: Props) {
  const { user } = useContext(UserContext);

  const handleAcceptTyC = () => {
    (async () => {
      let response = await acceptTyC();
      if (response !== undefined) {
        window.location.href = "/inicio";
      }
    })();
  };

  return (
    <Grid container className="BasicModal-conditions">
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={props.open}
        onClose={props.closeModal}
        closeAfterTransition
      >
        <Fade in={props.open}>
          <Grid className="basic-modal-conditions" container>
            <Box className="box-modal-conditions">
              <Grid className="children-conditions" container>
                <Grid className="logo-conditions" container>
                  <img width={110} src="images/LogoMarcos.png" alt="cocheras online" />
                </Grid>
                <Grid className="content" container>
                  <Grid container className="title-conditions">
                    <Typography>
                      <>
                        Te acercamos los términos y condiciones de nuestra aplicación
                      </>
                    </Typography>
                  </Grid>
                  <Grid container className="subtitle conditions">
                    <Typography variant="paragraph-20" type="semibold">
                      Tabla de contenido
                    </Typography>
                  </Grid>
                  <Grid container className="body-data">
                    <Typography variant="paragraph-20">
                      <>
                        1. Datos Identificativos.
                        <br />
                        2. Aceptación y acceso a la Plataforma.
                        <br />
                        3. Objeto y descripción de la Plataforma.
                        <br />
                        4. Cuenta de los Usuarios.
                        <br />
                        5. Responsabilidad por la información contenida en la
                        Plataforma.
                        <br />
                        6. Veracidad de la información.
                        <br />
                        7. Condiciones comerciales.
                        <br />
                        8. Terminación por el Usuario.
                        <br />
                        9. Sobre los métodos de pago utilizados en la
                        Plataforma.
                        <br />
                        10. Conducta del Usuario.
                        <br />
                        11. Exclusión de garantías y responsabilidad.
                        <br />
                        12. Suspensión y/o baja de la Cuenta de Usuario.
                        <br />
                        13. Mención del Usuario como cliente de Gestor Negocio.
                        <br />
                        14. Propiedad Industrial e intelectual.
                        <br />
                        15. Responsabilidad por descargas.
                        <br />
                        16. Compromiso de Indemnidad
                        <br />
                        17. Jurisdicción y Ley Aplicable.
                        <br />
                        18. Renuncias.
                        <br />
                        19. Medidas de Seguridad.
                        <br />
                        20. Nulidad e ineficacia de las cláusulas.
                        <br />
                        21. Textos legales que completan las Condiciones
                        Generales de Uso.
                      </>
                    </Typography>
                  </Grid>
                  <Grid container className="subtitle">
                    <Grid className="subtitle identification-data" container>
                      <Typography variant="paragraph-20" type="semibold">
                        1. Datos Identificativos
                      </Typography>
                    </Grid>
                    <Grid container className="body-data">
                      <Typography variant="paragraph-20">
                        Se informa que el titular del sitio web
                        https://gestornegocio.com (en adelante, la
                        “Plataforma”) es Gestor Negocio SRL Ante cualquier duda
                        sobre las presentes Condiciones Generales de Uso (en
                        adelante, las “Condiciones Generales de Uso”) y/o el
                        funcionamiento de la Plataforma y/o prestación de
                        servicios se solicita que se pongan en contacto con
                        Gestor Negocio al siguiente email:
                        gestornegocio2024@gmail.com
                      </Typography>
                    </Grid>
                    <Grid container>
                      <Grid className="subtitle" container>
                        <Typography variant="paragraph-20" type="semibold">
                          2. Aceptación y acceso a la Plataforma
                        </Typography>
                      </Grid>
                      <Grid container className="body-data">
                        <Typography variant="paragraph-20">
                          El acceso a la Plataforma y la utilización de sus
                          servicios está sujeto a la aceptación de las
                          Condiciones Generales de Uso, junto con la Política de
                          Privacidad. Cuando se accede a la Plataforma (en
                          adelante, también te denominamos como el “Usuario” o
                          los “Usuarios”) se entenderá que prestas tu
                          conformidad con las Condiciones Generales de Uso.
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid className="subtitle" container>
                      <Typography variant="paragraph-20" type="semibold">
                        3. Objeto y descripción de la Plataforma.
                      </Typography>
                    </Grid>
                    <Grid container className="body-data">
                      <Typography variant="paragraph-20">
                        Las Condiciones Generales de Uso regulan el acceso y el
                        uso de la Plataforma junto con el resto de condiciones
                        legales antes enunciadas. La Plataforma está destinada a
                        ofrecer un sistema integral de administración de
                        negocios que permite a los Propietarios
                        gestionar su operativa de manera eficiente.
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid className="subtitle" container>
                      <Typography variant="paragraph-20" type="semibold">
                        4. Cuenta de los Usuarios.
                      </Typography>
                    </Grid>
                    <Grid container className="body-data">
                      <Typography variant="paragraph-20">
                        La Plataforma ofrece a los Usuarios la posibilidad de
                        crear una cuenta a través de un formulario online que
                        deberá ser cumplimentado con los datos requeridos en el
                        mismo. La Plataforma únicamente permite el registro a
                        Usuarios que sean mayores de edad, esto es mayor de 18
                        años. La Plataforma deberá indicar una dirección de
                        correo electrónico, su nombre y apellido, y crear una
                        contraseña, que será de uso personal e intransferible.
                        Los Usuarios tendrán responsabilidad plena de uso de su
                        cuenta de Usuario. En consecuencia, los Usuarios son
                        responsables de la adecuada custodia y confidencialidad
                        del nombre de Usuario y/o contraseñas, así como todos
                        aquellos datos que le permitan el acceso a su cuenta, y
                        se comprometen a no ceder su uso a terceros, ya sea
                        temporal o permanente, ni a permitir su acceso a
                        terceras personas. En virtud de lo anterior, los
                        Usuarios deberán notificar de forma inmediata a Gestor
                        Negocio a través del correo electrónico indicado en el
                        apartado 1 cualquier uso indebido de su nombre de
                        Usuario y/o de su contraseña, debido a circunstancias
                        tales como el robo, extravío o el acceso no autorizado a
                        los mismos, con el fin de que Gestor Negocio pueda
                        proceder a su cancelación o bloqueo y/o inhabilitación
                        tan pronto como le conste la indebida utilización de su
                        Cuenta de Usuario. Mientras no se comuniquen tales
                        hechos, Gestor Negocio quedará eximido de cualquier
                        responsabilidad que pudiera derivarse del uso indebido
                        de los nombres de Usuarios o contraseñas por terceros no
                        autorizados.
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid className="subtitle" container>
                      <Typography variant="paragraph-20" type="semibold">
                        5. Responsabilidad por la información contenida en la
                        Plataforma.
                      </Typography>
                    </Grid>
                    <Grid container className="body-data">
                      <Typography variant="paragraph-20">
                        Gestor Negocio solo es Responsable del Tratamiento
                        respecto de los datos de personas físicas cuyos datos
                        son almacenados o procesados en la Plataforma para fines
                        propios por parte de la misma. Te aclaramos que Gestor
                        Negocio no se responsabiliza por los daños y perjuicios
                        que pudiera ocasionar el uso de la información que
                        provee la Plataforma. En ningún caso, Gestor Negocio
                        responderá ante el Usuario por el lucro cesante, la
                        pérdida de chances y/o toda consecuencia mediata o daño
                        indirecto derivado de cualquier circunstancia que se
                        vincule con la Plataforma.
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid className="subtitle" container>
                      <Typography variant="paragraph-20" type="semibold">
                        6. Veracidad de la información.
                      </Typography>
                    </Grid>
                    <Grid container className="body-data">
                      <Typography variant="paragraph-20">
                        El Usuario declara que la información proporcionada es
                        verdadera y que posee las facultades suficientes para
                        realizar las operaciones para las que se registra.
                        Además, el Usuario garantiza y responde, en todos los
                        casos, por la veracidad, vigencia y autenticidad de los
                        datos personales, societarios e información suministrada
                        y se obligan al cumplimiento de las presentes
                        Condiciones Generales de Uso, y a actuar siempre
                        conforme a la ley, a las buenas costumbres y a las
                        exigencias de la buena fe, absteniéndose de utilizar la
                        Plataforma de cualquier forma que pueda impedir, dañar o
                        deteriorar el normal funcionamiento de la misma, los
                        derechos de otros Usuarios, de Gestor Negocio o de
                        cualquier tercero. Por lo dicho, el Usuario es
                        responsable de la inclusión de datos en la Plataforma y
                        de disponer si es preciso de un sistema complementario
                        de copia de seguridad, no siendo en ningún caso Gestor
                        Negocio responsable de ninguna consecuencia que se derive
                        de lo anterior, imputable al Usuario registrado. Además,
                        Gestor Negocio se reserva el derecho de solicitar en
                        cualquier momento documentación o comprobantes para
                        corroborar la veracidad y exactitud de los datos
                        personales y/o societarios suministrados por el Usuario.
                        Por último, Gestor Negocio se reserva el derecho de
                        rechazar cualquier solicitud de registración o de
                        cancelar una registración previamente aceptada, sin que
                        esté obligado a comunicar o exponer las razones de su
                        decisión y sin que ello genere algún derecho a
                        indemnización o resarcimiento.
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid className="subtitle" container>
                      <Typography variant="paragraph-20" type="semibold">
                        7. Condiciones comerciales.
                      </Typography>
                    </Grid>
                    <Grid container className="body-data">
                      <Typography variant="paragraph-20">
                        La Plataforma permite la utilización de una versión de
                        prueba sin costo alguno durante 14 días. Luego de ello,
                        el acceso a la Plataforma se realizará a través de la
                        contratación de algún plan comercial que se ofrezca en
                        la Plataforma. Los precios de los servicios de la
                        Plataforma se establecen en Dólares Estadounidenses o
                        Pesos Argentinos. El plan contratado por el Usuario no
                        podrá ser inferior a un (1) año de duración y será
                        automáticamente renovado por igual período de tiempo
                        salvo que el Usuario comunique la baja con treinta (30)
                        días de anticipación. La facturación por los servicios
                        de la Plataforma se realizará en formato digital. Por lo
                        tanto, al momento de la registración, el Usuario deberá
                        denunciar una dirección de mail principal y una
                        alternativa a donde enviar la facturación electrónica.
                        La falta de pago en término de las facturas emitidas,
                        producirá la mora de pleno derecho, sin necesidad de
                        interpelación previa alguna y las sumas adeudadas
                        devengarán un interés desde el día siguiente al del
                        vencimiento de la factura y hasta la fecha de su
                        efectivo pago, calculado en base a una tasa del doce por
                        ciento (12%) nominal anual en Dólares Estadounidenses.
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid className="subtitle" container>
                      <Typography variant="paragraph-20" type="semibold">
                        8. Terminación por el Usuario.
                      </Typography>
                    </Grid>
                    <Grid container className="body-data">
                      <Typography variant="paragraph-20">
                        El Usuario podrá en cualquier momento rescindir el plan
                        contratado de forma unilateral y sin expresión de causa,
                        solicitando a Gestor Negocio la baja del mismo. La baja
                        será operativa a partir del mes siguiente a su
                        solicitud. En caso de solicitar la referida baja, el
                        Usuario no tendrá derecho a reintegro y/o devolución
                        alguna a su favor sobre el plan contratado, renunciando
                        a cualquier acción y/o reclamo contra Gestor Negocio.
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid className="subtitle" container>
                      <Typography variant="paragraph-20" type="semibold">
                        9. Sobre los métodos de pago utilizados en la
                        Plataforma.
                      </Typography>
                    </Grid>
                    <Grid container className="body-data">
                      <Typography variant="paragraph-20">
                        La Plataforma utiliza efectivo, transferencia o débito
                        directo como método de pago. Asimismo, la Plataforma
                        permite la utilización de ciertas tarjetas de crédito o
                        débito al momento de su solicitud de reserva. Sin
                        perjuicio de ello, ante cualquier duda, sugerimos
                        revisar los términos y condiciones y demás políticas
                        aplicables utilizadas por Gestor Negocio. Se aclara que
                        Gestor Negocio no se responsabiliza por cualquier
                        desperfecto, error, falla o inconveniente que haya
                        sufrido el Usuario al utilizar los medios de pago
                        habilitados y/o al utilizar las tarjetas habilitadas por
                        Pagos Online.
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid className="subtitle" container>
                      <Typography variant="paragraph-20" type="semibold">
                        10. Conducta del Usuario.
                      </Typography>
                    </Grid>
                    <Grid container className="body-data">
                      <Typography variant="paragraph-20">
                        <>
                          Se deja expresa constancia que se encuentra
                          terminantemente prohibido para el Usuario descargar
                          y/o reproducir y/o ceder a terceros cualquier tipo de
                          imagen obtenida de la Plataforma, inclusive a través
                          de una imagen fotográfica y/o captura de pantalla,
                          salvo lo expresamente permitido por Gestor Negocio.
                          En este caso, te hacemos saber que serás responsable
                          de todas las operaciones realizadas en tu Cuenta. En
                          caso de que detectes cualquier uso o ingreso no
                          autorizado en tu cuenta, deberás hacérselo saber a
                          Gestor Negocio de forma inmediata. La Cuenta de
                          Usuario no es una propiedad del Usuario, sino una
                          herramienta que Gestor Negocio pone a tu disposición
                          para acceder a los servicios de la Plataforma. Queda
                          terminantemente prohibida la venta, cesión, y
                          transferencia de la Cuenta de Usuario bajo ningún
                          título. Asimismo, se le prohíbe Usuario las siguientes
                          acciones:
                          <br />
                          <br />- Utilizar mecanismos, software o scripts en
                          relación con la utilización de la Plataforma;
                          <br />
                          <br />- Bloquear, sobrescribir, modificar o copiar, a
                          no ser que ello sea necesario para la correcta
                          utilización de los servicios de la Plataforma en
                          general;
                          <br />
                          <br />- Difundir y reproducir públicamente contenidos
                          de la Plataforma de Gestor Negocio o de otros
                          Usuarios, sin la autorización previa;
                          <br />
                          <br />- Utilizar cualquiera de los materiales e
                          informaciones contenidos en la Plataforma con fines
                          ilícitos y expresamente prohibidos en las presentes
                          Condiciones Generales de Uso.
                          <br />
                          <br />
                          El Usuario es consciente, y acepta voluntariamente,
                          que el uso del servicio tiene lugar, en todo caso,
                          bajo su única y exclusiva responsabilidad. El Usuario
                          responderá de los daños y perjuicios de toda
                          naturaleza que Gestor Negocio pueda sufrir como
                          consecuencia del incumplimiento de cualquiera de las
                          obligaciones a las que queda sometido por virtud de
                          estas “Condiciones Generales de Uso” o de la ley en
                          relación con la Utilización del Servicio.
                        </>
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid className="subtitle" container>
                      <Typography variant="paragraph-20" type="semibold">
                        11. Exclusión de garantías y responsabilidad.
                      </Typography>
                    </Grid>
                    <Grid container className="body-data">
                      <Typography variant="paragraph-20">
                        Salvo en aquellos casos expresamente descritos en las
                        Condiciones Generales de Uso, Gestor Negocio no se
                        responsabilizará por los daños y perjuicios de toda
                        naturaleza que puedan deberse a la falta de exactitud,
                        exhaustividad, actualidad, así como a errores u
                        omisiones de los que pudieran adolecer las informaciones
                        y servicios contenidos en la Plataforma u otros
                        contenidos a los que se pueda acceder a través del mismo
                        ni asume ningún deber o compromiso de verificar ni de
                        vigilar sus contenidos e informaciones. Asimismo,
                        Gestor Negocio no garantiza la disponibilidad,
                        continuidad ni la infalibilidad del funcionamiento de la
                        Plataforma, y en consecuencia, excluye cualquier
                        responsabilidad por los daños y perjuicios de toda
                        naturaleza que puedan deberse a la falta de
                        disponibilidad o de continuidad del funcionamiento de la
                        Plataforma y de los servicios habilitados en el mismo,
                        así como a los errores en el acceso a las distintas
                        páginas web o aquellas desde las que, en su caso, se
                        presten dichos servicios. Gestor Negocio no asume
                        responsabilidad alguna por los contenidos, datos y/o
                        información aportados por los Usuarios de la Plataforma,
                        ni tampoco por los contenidos de sitios web externos a
                        los que existan enlaces. En especial, Gestor Negocio no
                        garantiza que esos contenidos sean verdaderos, cumplan
                        una determinada finalidad o puedan servir a esa
                        finalidad. Asimismo, Gestor Negocio no será responsable
                        de las opiniones vertidas por los Usuarios a través del
                        portal, los foros, comunidades, y otras herramientas de
                        participación u opinión. Aclaramos que Gestor Negocio
                        no está obligado a proveer ningún equipo o programa de
                        software para acceder a la Plataforma. El Usuario deberá
                        contar con todos los recursos necesarios para ello,
                        incluidos los de telecomunicaciones. Por último,
                        Gestor Negocio podrá eliminar de la Plataforma
                        contenidos ilegales sin previo aviso. El Usuario
                        reconoce que técnicamente no es posible lograr
                        disponibilidad de la Plataforma al 100%. Sin embargo,
                        Gestor Negocio se esforzará por mantener disponible la
                        Plataforma de la forma más constante posible.
                        Especialmente, por razones de mantenimiento, seguridad o
                        capacidad, así como a causa de acontecimientos sobre los
                        que no puede influir Gestor Negocio (por ejemplo,
                        anomalías de redes públicas de comunicación, cortes de
                        electricidad, etc.), pueden producirse breves anomalías
                        o la suspensión pasajera de los servicios de la
                        Plataforma.
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid className="subtitle" container>
                      <Typography variant="paragraph-20" type="semibold">
                        12. Suspensión y/o baja de la Cuenta de Usuario.
                      </Typography>
                    </Grid>
                    <Grid container className="body-data">
                      <Typography variant="paragraph-20">
                        <>
                          Gestor Negocio se reserva el derecho de suspender o
                          darte de baja de la Plataforma en los siguientes
                          casos:
                          <br />
                          <br />- Cuando el Usuario se encuentre en mora en el
                          pago de alguna factura emitida por Gestor Negocio.
                          <br />
                          <br />
                          - Cuando hayas suministrado a Gestor Negocio
                          información sobre datos personales y/o societarios que
                          no hayan podido ser confirmados o que resulten falsos
                          o inexactos.
                          <br />
                          <br />- Cuando hicieras un uso indebido de la
                          Plataforma para propósitos ilegales, abusivos,
                          difamatorios, y/o para facilitar o promover
                          actividades en competencia con Gestor Negocio.
                          <br />
                          <br />- Cuando a criterio de Gestor Negocio se
                          encuentre en riesgo la seguridad de la Plataforma u
                          otro riesgo percibido contra la seguridad de la
                          información contenida en la misma.
                          <br />
                          <br />- Cuando a criterio de Gestor Negocio se
                          produjera algún otro evento de gravedad que justifique
                          la decisión de suspender temporal o definitivamente al
                          Usuario.
                          <br />
                          <br />- Cuando la cuenta estuviera inactiva por el
                          plazo de doce (12) meses, Gestor Negocio se reserva
                          el derecho de cerrar la misma.
                        </>
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid className="subtitle" container>
                      <Typography variant="paragraph-20" type="semibold">
                        13. Mención del Usuario como cliente de Gestor Negocio.
                      </Typography>
                    </Grid>
                    <Grid container className="body-data">
                      <Typography variant="paragraph-20">
                        Como Usuario, al registrarte en la Plataforma, autorizas
                        a Gestor Negocio a que te mencionemos como cliente, así
                        como publicar tu nombre, marcas y/o logotipo y/o
                        isotipo, y/o cualquier otro signo que te represente e
                        identifique con el fin de dar a conocer su presencia y/o
                        actividad.
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid className="subtitle" container>
                      <Typography variant="paragraph-20" type="semibold">
                        14. Propiedad Industrial e intelectual.
                      </Typography>
                    </Grid>
                    <Grid container className="body-data">
                      <Typography variant="paragraph-20">
                        Todos los contenidos que forma parte de la Plataforma
                        (información, artículos, datos, textos, logos, iconos,
                        imágenes, apariencia externa, look and feel, sonidos,
                        audio, video, diseños, creatividades, software, etc.)
                        pertenecen a Gestor Negocio o se explotan bajo licencia
                        de terceros titulares de los derechos de propiedad
                        intelectual de los mencionados contenidos, y están
                        protegidos por la legislación vigente en materia de
                        propiedad industrial e intelectual. Cualquier
                        reproducción, transmisión, adaptación, traducción,
                        modificación, comunicación al público, o cualquier otra
                        explotación de todo o parte del contenido de la
                        Plataforma, efectuada de cualquier forma o por cualquier
                        medio, electrónico, mecánico u otro, están estrictamente
                        prohibidos salvo autorización previa por escrito de
                        Gestor Negocio o de los terceros titulares. Cualquier
                        infracción de estos derechos puede dar lugar a los
                        procedimientos extrajudiciales o judiciales civiles o
                        penales que correspondan. Gestor Negocio no concede
                        ninguna licencia o autorización de uso de ninguna clase
                        sobre sus derechos de propiedad intelectual e industrial
                        o sobre cualquier otra propiedad o derecho relacionado
                        con la Plataforma, los servicios o los contenidos de la
                        misma.
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid className="subtitle" container>
                      <Typography variant="paragraph-20" type="semibold">
                        15. Responsabilidad por descargas.
                      </Typography>
                    </Grid>
                    <Grid container className="body-data">
                      <Typography variant="paragraph-20">
                        Gestor Negocio hace saber que corre por tu cuenta
                        verificar la inexistencia de virus en los programas y
                        materiales que descargues en tu equipo (en adelante, los
                        “Materiales de Descarga”) para la utilización de la
                        Plataforma. En ningún caso Gestor Negocio será
                        responsable por los daños causados por elementos
                        destructivos que pudieran haber introducido terceros a
                        los Materiales de Descarga provistos por el Usuario, ni
                        por los Materiales de Descarga suministrados por los
                        proveedores de información o un tercero sin autorización
                        expresa de Gestor Negocio.
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid className="subtitle" container>
                      <Typography variant="paragraph-20" type="semibold">
                        16. Compromiso de Indemnidad
                      </Typography>
                    </Grid>
                    <Grid container className="body-data">
                      <Typography variant="paragraph-20">
                        El Usuario se obliga a mantener indemne a Gestor
                        Negocio, a sus dependientes, licenciantes, proveedores y
                        licenciatarios de los daños resultantes (incluyendo los
                        costos de defensa) de cualquier acto o hecho ilícito
                        imputable al Usuario o a un tercero que utilice la
                        cuenta del Usuario. Gestor Negocio podrá modificar,
                        eliminar y/o agregar términos y/o condiciones en
                        cualquiera de las cláusulas del presente contrato, sin
                        la previa conformidad del Cliente, para la cual te
                        daremos aviso de dichos cambios. Gestor Negocio se
                        reserva el derecho de modificar la estructura de la
                        Plataforma en la oportunidad y en la condición que lo
                        considere oportuno y conveniente. Estas modificaciones
                        serán válidas desde su publicación en la Plataforma.
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid className="subtitle" container>
                      <Typography variant="paragraph-20" type="semibold">
                        17. Jurisdicción y Ley Aplicable.
                      </Typography>
                    </Grid>
                    <Grid container className="body-data">
                      <Typography variant="paragraph-20">
                        Toda cuestión entre Gestor Negocio y el Usuario estará
                        regida por las leyes de la República Argentina, que, en
                        su caso conocerán el asunto con exclusión de toda norma
                        que remita a la aplicación de una ley extranjera.
                        Cualquier controversia será sometida a la Justicia
                        Nacional en lo Comercial, con asiento en la Ciudad
                        Autónoma de Buenos Aires.
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid className="subtitle" container>
                      <Typography variant="paragraph-20" type="semibold">
                        18. Renuncias.
                      </Typography>
                    </Grid>
                    <Grid container className="body-data">
                      <Typography variant="paragraph-20">
                        La demora u omisión de Gestor Negocio en exigir el
                        estricto cumplimiento de estas Condiciones Generales de
                        Uso no podrá interpretarse como renuncia a sus derechos.
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid className="subtitle" container>
                      <Typography variant="paragraph-20" type="semibold">
                        19. Medidas de Seguridad.
                      </Typography>
                    </Grid>
                    <Grid container className="body-data">
                      <Typography variant="paragraph-20">
                        Gestor Negocio se preocupa por defender el derecho a la
                        privacidad de los Usuarios. Por eso, adopta en la
                        Plataforma todas las medidas técnicas y organizativas
                        necesarias para proteger en su sistema la seguridad e
                        integridad de la Información Personal frente a accesos
                        no autorizados y también contra la alteración, pérdida o
                        destrucción accidentales. Se informa a los Usuarios que
                        sus datos se almacenarán bajo un servicio en la nube
                        protegidos por sistemas de seguridad que impiden el
                        acceso de terceros no autorizados a los mismos. La
                        transmisión de datos e Información Personal por los
                        Usuarios a través de la Plataforma se realizará de forma
                        encriptada y se encuentra protegida por técnicas de
                        seguridad que aseguran la transmisión segura de los
                        datos bajo protocolo https. Gestor Negocio realiza sus
                        mejores esfuerzos para disponer de los sistemas más
                        actualizados para la eficacia de estos sistemas de
                        seguridad en la Plataforma. A pesar de lo anterior, no
                        se puede garantizar la seguridad absoluta de la
                        Información Personal en la Plataforma puesto que el uso
                        de tecnologías precisamente está sujeto a riesgos
                        derivadas del uso de las mismas, en especial, por parte
                        de terceros, no pudiendo evitar que hackers, piratas
                        informáticos u otras personas puedan intentar ataques
                        contra nuestros sistemas.
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid className="subtitle" container>
                      <Typography variant="paragraph-20" type="semibold">
                        20. Nulidad e ineficacia de las cláusulas.
                      </Typography>
                    </Grid>
                    <Grid container className="body-data">
                      <Typography variant="paragraph-20">
                        Todas las cláusulas o extremos de estas Condiciones
                        Generales de Uso deben ser interpretadas de forma
                        independiente y autónoma, no viéndose afectadas el resto
                        de estipulaciones. Si cualquier cláusula incluida en
                        estas Condiciones Generales de Uso fuese declarada,
                        total o parcialmente, nula o ineficaz, tal nulidad o
                        ineficacia afectará tan sólo a dicha disposición o a la
                        parte de la misma que resulte nula o ineficaz,
                        subsistiendo las Condiciones Generales de Uso en todo lo
                        demás, teniéndose tal disposición, o la parte de la
                        misma que resultase afectada, por no escrita.
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid className="subtitle" container>
                      <Typography variant="paragraph-20" type="semibold">
                        21. Textos legales que completan las condiciones
                        generales de uso.
                      </Typography>
                    </Grid>
                    <Grid container className="body-data">
                      <Typography variant="paragraph-20">
                        Este documento hace parte integrante y se complementa
                        con el resto de las políticas legales aplicables a la
                        Plataforma, como la Política de Privacidad y la Política
                        de Cookies, sin perjuicio de que pudiera complementarse
                        con otros textos que, en su caso, serían publicados en
                        la Plataforma.
                      </Typography>
                    </Grid>
                  </Grid>
                  {user && (
                    <Grid className="button-accept" container>
                      <ButtonLevel
                        onClick={handleAcceptTyC}
                        title="Acepto Términos y condiciones"
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Fade>
      </Modal>
    </Grid>
  );
}
