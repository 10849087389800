import { Grid } from "@mui/material";
import TextInput from "../../kit/Inputs/Text";
import "./UpdateDrawerProduct.scss";
import { useEffect, useState } from "react";
import { DrawerStatus, SideDrawer } from "../../kit/SideDrawer";
import { postNewProduct, Product, ProductDTO } from "../../../services/product";
import NumberInput from "../../kit/Inputs/Number";
import { getAllTag, Tag } from "../../../services/tags";
import SelectInput from "../../kit/Inputs/Select";

interface Props {
  product: Product;
  closeDrawer: () => void;
  openDrawer: boolean;
  updatePage: () => void;
  supplierId: number;
}

export default function UpdateProductDrawer(props: Props) {
  const [productDTO, setProductDTO] = useState<ProductDTO>({
    ...props.product,
    tagId: props?.product?.tag?.id,
    supplierId: props.supplierId
  });

  const [drawerStatus, setDrawerStatus] = useState<DrawerStatus>("idle");
  const [tags, setTags] = useState<Tag[]>([]);

  const handleUpdateProduct = () => {
    (async () => {
      let response = await postNewProduct(productDTO);
      if (response.status === 201) {
        props.updatePage();
        setDrawerStatus("success");
      } else {
        setDrawerStatus("error");
      }
    })();
  };

  const handleGetTags = () => {
    (async () => {
      let response = await getAllTag();
      if (response.status === 200) {
        setTags(response.data?.tags || []);
      }
    })();
  };
  useEffect(() => {
    handleGetTags();
  }, []);

  const disableSupplierDTO = () => {
    if (productDTO.name === "") return true;
    return false;
  };

  return (
    <Grid container>
      <SideDrawer
        title="Editar producto"
        disabled={disableSupplierDTO()}
        saveOnclick={() => {
          handleUpdateProduct();
          setDrawerStatus("success");
        }}
        saveLabel="Guardar"
        closeDrawer={props.closeDrawer}
        open={props.openDrawer}
        drawerStatus={drawerStatus}
        onRetry={() => setDrawerStatus("idle")}
      >
        <Grid className="update-product-drawer" container>
          <Grid className="update-product-drawer-item" container>
            <SelectInput
              itemSelected={productDTO.type}
              onChange={(type) =>
                setProductDTO((prevState) => ({
                  ...prevState,
                  type,
                }))
              }
              label="Tipo"
              items={[{ value: 'unit', label: 'Unidad' }, { value: 'weight', label: 'Peso' }]}
            />
          </Grid>
          <Grid className="update-product-drawer-item" container>
            <TextInput
              value={productDTO.name}
              onChange={(name) =>
                setProductDTO((prevState) => ({
                  ...prevState,
                  name,
                }))
              }
              label={<span className="required-label">Nombre</span>}
            />
          </Grid>
          <Grid className="update-product-drawer-item" container>
            <TextInput
            value={productDTO.code}
              onChange={(code) =>
                setProductDTO((prevState) => ({
                  ...prevState,
                  code,
                }))
              }
              label="Código"
            />
          </Grid>
          <Grid className="update-product-drawer-item" container>
            <NumberInput
              value={productDTO.purchasePrice || ""}
              onChange={(purchasePrice) =>
                setProductDTO((prevState) => ({
                  ...prevState,
                  purchasePrice,
                }))
              }
              label="Precio compra"
            />
          </Grid>
          <Grid className="update-product-drawer-item" container>
            <NumberInput
              value={productDTO.amount || ""}
              onChange={(amount) =>
                setProductDTO((prevState) => ({
                  ...prevState,
                  amount,
                }))
              }
              label={<span className="required-label">Precio de venta</span>}
            />
          </Grid>
          <Grid className="update-product-drawer-item" container>
            <SelectInput
              itemSelected={productDTO.tagId}
              onChange={(tagId: number) =>
                setProductDTO((prevState) => ({
                  ...prevState,
                  tagId,
                }))
              }
              label="Etiqueta"
              items={tags.map((tag: Tag) => ({
                value: tag.id,
                label: tag.name,
              }))}
            />
          </Grid>
        </Grid>
      </SideDrawer>
    </Grid>
  );
}
