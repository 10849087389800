import { useSearchParams } from "react-router-dom";

import { Grid } from "@mui/material";
import CardTags from "../../components/Tag/TagCard";
import { TagTable } from "../../components/Tag/TagTable";

export default function Tags() {
  const [searchParams] = useSearchParams();
  const tagId = searchParams.get("tid");

  return (
    <Grid container className="tags">
      {tagId !== null ? <TagTable tagId={parseInt(tagId)} /> : <CardTags />}
    </Grid>
  );
}
