
import { Grid } from "@mui/material";
import { PromotionTable } from "../../components/Promotion/PromotionTable";

export default function PromotionPage() {

  return (
    <Grid container className="promotion-page">
      <PromotionTable />
    </Grid>
  );
}
