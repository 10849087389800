import React, { useState } from "react";
import { TextField } from "@material-ui/core";
import moment from "moment";
import { Grid } from "@mui/material";
import icons from "../../../../utils/icons";
import DatePicker from "react-datepicker";

import "./Date.scss";
import ButtonLevel from "../../Buttons";
import Typography from "../../Typography";
import { grayColor, primaryColor1 } from "../../../../utils/VARIABLES";
import { es } from 'date-fns/locale';
require("react-datepicker/dist/react-datepicker.css");

interface Props {
  label: string | React.ReactElement;
  date: string;
  onChange: (_value: string) => void;
  variant: "calendar" | "calandarWithHours" | "period" | "calendarOnlyHours";
  disabled?: boolean;
  placeholder?: string;
  minDate?: string | Date;
  maxDate?: string | Date;
}

export default function DateInput(props: Props) {
  const {
    label = "",
    date,
    onChange,
    variant,
    minDate,
    maxDate,
    disabled = false,
    placeholder = "Selecciona una fecha...",
  } = props;

  const days = [
    "Lunes",
    "Martes",
    "Miercoles",
    "Jueves",
    "Viernes",
    "Sabado",
    "Domingo",
  ];
  const months = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];

  const localeForPeriod: any = {
    formatLong: {
      date: ({
        year,
        month,
        day,
      }: {
        year: number;
        month: number;
        day: number;
      }) => `${months[month]} ${year}`,
    },
    localize: {
      // If needs the full name of the day or month, needs add a new prop and change the slice
      day: (n: number) => days[n].slice(0, 3),
      month: (n: number) => months[n].slice(0, 3),
    },
  };

  const localeForDay: any = {
    localize: {
      day: (n: number) => days[n][0],
      month: (n: number) => months[n],
    },
    formatLong: {
      date: () => "mm/dd/yyyy",
    },
  };

  return (
    <Grid container className="date-input">
      <Typography variant="paragraph-14" text={props.label as string} />
      {variant === "calendar" && (
        <DatePicker
          locale={localeForDay}
          selected={date ? moment(date).toDate() : null}
          value={moment(date).format("DD-MM-YYYY")}
          onChange={(date: Date | null) => {
            if (!date) date = new Date();
            date = moment(date).toDate();
            if (date) {
              // date.setDate(date.getDate() + 1);
              onChange(date.toDateString());
            } else onChange("");
          }}
          customInput={
            <TextField
              className="date-picker-input"
              placeholder={placeholder}
              size="small"
              variant="outlined"
              color="secondary"
              InputProps={{
                endAdornment: icons.calendar({
                  width: 20,
                  height: 20,
                  color: primaryColor1,
                }),
              }}
            />
          }
          renderCustomHeader={({
            date,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled,
          }) =>
            customDatePickerHeader(
              `${months[moment(date).month()]} ${date.getFullYear()}`,
              decreaseMonth,
              increaseMonth,
              prevMonthButtonDisabled,
              nextMonthButtonDisabled,
              "day"
            )
          }
          minDate={minDate ? moment(minDate).toDate() : undefined}
          maxDate={
            maxDate ? moment(maxDate).add(3, "hours").toDate() : undefined
          }
        />
      )}
      {variant === "calandarWithHours" && (
        <Grid container>
          <DatePicker
            locale={es}
            value={moment(date).format("DD-MM-YYYY HH:mm")}
            customInput={
              <TextField
                className="date-picker-input"
                placeholder={placeholder}
                size="small"
                variant="outlined"
                color="secondary"
                InputProps={{
                  endAdornment: icons.calendar({
                    width: 20,
                    height: 20,
                    color: primaryColor1,
                  }),
                }}
              />
            }
            selected={date ? moment(date).toDate() : null}
            onChange={(selectedDate: Date | null) => {
              if (!selectedDate) selectedDate = new Date();
              if (selectedDate) {
                // date.setDate(date.getDate() + 1);
                onChange(selectedDate.toISOString());
              } else onChange("");
            }}
            renderCustomHeader={({
              date,
              decreaseMonth,
              increaseMonth,
              prevMonthButtonDisabled,
              nextMonthButtonDisabled,
            }) =>
              customDatePickerHeader(
                `${months[moment(date).month()]} ${date.getFullYear()}`,
                decreaseMonth,
                increaseMonth,
                prevMonthButtonDisabled,
                nextMonthButtonDisabled,
                "hours"
              )
            }
            showTimeSelect
            timeFormat="HH:mm"
            timeIntervals={5}
          />
        </Grid>
      )}
      {variant === "period" && (
        <DatePicker
          locale={localeForPeriod}
          wrapperClassName="react-date-picker"
          placeholderText={placeholder}
          showMonthYearPicker
          className="inputs"
          onChange={(date: Date | null) => {
            if (!date) date = new Date();
            date = moment(date).toDate();
            if (date) {
              // date.setDate(date.getDate() + 1);
              onChange(date.toISOString().split("T")[0]);
            } else onChange("");
          }}
          disabled={disabled}
          value={`${months[moment(date).month()]} ${moment(date).year()}`}
          customInput={
            <TextField
              className="date-picker-input"
              placeholder={placeholder}
              size="small"
              variant="outlined"
              color="secondary"
              InputProps={{
                endAdornment: icons.calendar({
                  width: 20,
                  height: 20,
                  color: primaryColor1,
                }),
              }}
            />
          }
          disabledKeyboardNavigation
          selected={date ? moment(date).toDate() : null}
          minDate={minDate ? moment(minDate).toDate() : undefined}
          maxDate={
            maxDate ? moment(maxDate).add(3, "hours").toDate() : undefined
          }
          showDisabledMonthNavigation
          renderCustomHeader={({
            date,
            decreaseYear,
            increaseYear,
            prevYearButtonDisabled,
            nextYearButtonDisabled,
          }) =>
            customDatePickerHeader(
              date.getFullYear(),
              decreaseYear,
              increaseYear,
              prevYearButtonDisabled,
              nextYearButtonDisabled,
              "period"
            )
          }
        />
      )}
      {variant === "calendarOnlyHours" && (
        <Grid container>
          <DatePicker
            selected={date ? moment(date, "HH:mm").toDate() : null}
            onChange={(date: Date) => {
              date = moment(date).toDate();

              // Get the time in the format HH:mm
              const time = moment(date).format("HH:mm");
              onChange(time);
            }}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={10}
            timeCaption="Time"
            timeFormat="HH:mm"
            dateFormat="HH:mm"
            popperClassName="custom-datepicker-time"
          />
        </Grid>
      )}
    </Grid>
  );
}

function customDatePickerHeader(
  date: string | number,
  decrease: () => void,
  increase: () => void,
  disabledDecrease: boolean,
  disabledIncrease: boolean,
  type: "period" | "day" | "hours" = "day"
) {
  return (
    <Grid
      columnGap={type === "hours" ? 4 : 6}
      container
      alignItems="center"
      className={`date-picker-popper-header-${type}`}
    >
      <ButtonLevel
        icon={{
          icon: icons.previousArrow({
            color: disabledDecrease ? grayColor : primaryColor1,
          })
        }}
        onClick={decrease}
        disabled={disabledDecrease}
      />
      <Typography text={date} variant="input1" type={type === "hours" ? "regular" : "bold"} />
      <ButtonLevel
        icon={{
          icon: icons.nextArrow({
            color: disabledIncrease ? grayColor : primaryColor1,
          })
        }}
        onClick={increase}
        disabled={disabledIncrease}
      />
    </Grid>
  );
}
