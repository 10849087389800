import axios from "axios";
import { API_URL } from "../../config/env";
import { getToken } from "../utils/token";
import {
  PaginateOptions,
  PaginatedResponse,
  defaultPaginateOptions,
  defaultPaginatedResponse,
} from "../../utils/generalServiceResponses";

export type AccountStatusResultData = {
  amount: number;
  date: string;
  outcome: number;
  income: number;
};

export async function getAccountStatusGroupingByDate(
  paginateOptions?: PaginateOptions,
  dateFrom?: string,
  dateTo?: string
): Promise<PaginatedResponse<AccountStatusResultData>> {
  if (paginateOptions === undefined)
    paginateOptions = { ...defaultPaginateOptions, limit: 100 };

  if (dateFrom) dateFrom = new Date(dateFrom).toISOString().split("T")[0];
  if (dateTo) dateTo = new Date(dateTo).toISOString().split("T")[0];

  const token = await getToken();

  try {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios.get(API_URL + "/movement/grouping-by-date", {
      headers,
      params: {
        search: paginateOptions.search,
        page: paginateOptions.page,
        limit: paginateOptions.limit,
        dateFrom,
        dateTo,
      },
    });
    return response.data;
  } catch (e) {
    console.warn(e);
    return defaultPaginatedResponse;
  }
}

export type Account = {
  id: number;
  amount: number;
  amountIncome: number;
  amountOutcome: number;
};

export async function getCurrentAccount(): Promise<Account | null> {
  const token = await getToken();
  try {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios.get(API_URL + "/account", {
      headers,
    });
    return response.data;
  } catch (e) {
    console.warn(e);
    return null;
  }
}
