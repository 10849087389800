import { Grid } from "@mui/material";

interface Props {
  id: number; // customerId
}
export default function CustomerTicket(props: Props) {

  return (
    <Grid container className="customer-ticket">
      Ticket
    </Grid>
  )
}