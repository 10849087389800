import { useSearchParams } from "react-router-dom";
import { Grid } from "@mui/material";
import { CustomerPage } from "../../components/Customer/CustomerPage";
import CustomerTicket from "../../components/Customer/CustomerTicket";

export default function Customer() {
  const [searchParams] = useSearchParams();
  const ticketId = searchParams.get("id");

  return (
    <Grid container>
      {!ticketId ? (
        <CustomerPage />
      ) : (
        <CustomerTicket id={parseInt(ticketId)} />
      )}
    </Grid>
  );
}
