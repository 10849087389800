import { Grid, TextField } from "@mui/material";
import "./TextInputMultiline.scss";
import Typography from "../../Typography";

interface Props {
  value?: string;
  disabled?: boolean;
  label?: string;
  placeholder?: string;
  onChange: (_value: string) => void;
  rows: number;
  helperText?: {
    text: string;
    type: "sucess" | "warning" | "error";
  };
}
export default function MultilineInput(props: Props) {
  const { value, disabled = false } = props;
  return (
    <Grid container className="multiline-input">
      <Typography text={props.label} variant="paragraph-14" />
      <Grid className="text-field-multiline" container>
        <TextField
          placeholder={props.placeholder}
          value={value}
          id="outlined-multiline-static"
          multiline
          rows={props.rows}
          className="text-input-multiline"
          color="secondary"
          onChange={(event) => props.onChange(event.target.value)}
          variant="outlined"
          disabled={disabled}
          InputLabelProps={{ shrink: false }}
        />
        <Grid container>
          <Typography
            className={`helper-text ${props.helperText?.type}`}
            variant="paragraph-12"
          >
            {props.helperText?.text}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}
