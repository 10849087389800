import { ClickAwayListener, Grid, Popper } from "@mui/material";
import React, { useRef, useState } from "react";
import icons from "../../../utils/icons";
import { grayColor, primaryColor2, secondaryColor2, whiteColor } from "../../../utils/VARIABLES";
import "./Partnerships.scss";
import Typography from "../../../components/kit/Typography";
import ButtonLevel from "../../../components/kit/Buttons";
import { logo } from "../../../utils/logo-svg";

export default function PartnershipsMobilePage() {
  const [menuSelected, setMenuSelected] = useState(0);
  const seccionInicioRef = useRef<HTMLDivElement>(null);
  const seccionBeneficiosRef = useRef<HTMLDivElement>(null);
  const seccionReferinosRef = useRef<HTMLDivElement>(null);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClickHead = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  }

  const onClosePopper = () => {
    setAnchorEl(null);
  }

  const handleScroll = (ref: React.RefObject<HTMLDivElement>) => {
    if (ref.current) {
      const yOffset = -120;

      window.scrollTo({
        top: ref.current.offsetTop + yOffset,
        behavior: "smooth"
      });
    }
    setAnchorEl(null);
  };

  function getMoreInfoWsp() {
    var phoneNumber = "5493546513483";
    var message = "Hola, me gustaría recibir más información sobre el programa de aliados.";
    var url = "https://api.whatsapp.com/send?phone=" + phoneNumber + "&text=" + encodeURIComponent(message);
    window.open(url, '_blank');
  }

  return (
    <Grid container className="partnerships-mobile-page">
      <Grid container className="menu-top" alignContent="center" alignItems="center" ref={seccionInicioRef}>
        <Grid item>
          {logo()}
        </Grid>
        <Grid item xs textAlign="end">
          <button
            style={{
              border: "none",
              background: "none",
              cursor: "pointer",
            }}
            onClick={(event) => handleClickHead(event)}
          >
            {icons.menu({
              color: primaryColor2
            })}
          </button>
        </Grid>
      </Grid>
      <Grid container className="partnerships-content">
        <Grid container className="logo" justifyContent="center" alignItems="center">
          <Grid item>
            {partnerShipSVGicon()}
          </Grid>
          <Grid item>
            <Typography color={whiteColor} variant="paragraph-24" type="bold">ALIADOS</Typography>
          </Grid>
        </Grid>
        <Grid container className="content" justifyContent="center">
          <Grid container className="titles">
            <Grid container className="title-1" justifyContent="center">
              <Typography type="bold" color={whiteColor} variant="paragraph-24">
                <>
                  Incrementá tus ganancias <br />
                  con Cocheras:
                </>
              </Typography>
            </Grid>
            <Grid container className="title-2" justifyContent="center">
              <Typography type="bold" variant="paragraph-24">
                <>
                  Programa de Aliados
                </>
              </Typography>
            </Grid>
          </Grid>
          <Grid container className="subtitle" justifyContent="center">
            <Typography color={secondaryColor2} variant="paragraph-20">
              <>
                Es un programa donde los clientes o socios comerciales de Gestor Negocio pueden aumentar sus ingresos al conectar a sus colegas o conocidos con nuestro sistema.
              </>
            </Typography>
          </Grid>
          <Grid container className="button-to-get-more-info">
            <ButtonLevel
              color="secondary"
              variant="contained"
              title="Más información"
              onClick={getMoreInfoWsp}
            />
          </Grid>
        </Grid>
        <Grid container className="benefits" ref={seccionBeneficiosRef}>
          <Grid container className="title" justifyContent="center">
            <Typography color={whiteColor} variant="paragraph-24" type="bold">
              <>
                Elegí el beneficio que <br /> mejor se adapte a tu negocio
              </>
            </Typography>
          </Grid>
          <Grid container className="card tiered-commissions">
            <Grid container className="icon" justifyContent="center">
              {benefitsIcons('tiered-commissions')}
            </Grid>
            <Grid container justifyContent="center" className="title">
              <Typography variant="paragraph-18" type="medium">
                Comisiones escalonadas
              </Typography>
            </Grid>
            <Grid container className="description">
              <Typography variant="paragraph-16">
                <>
                  Por los próximos 3 meses de facturación y en base al volumen de facturación, arrancas entre el 10-15% del margen generado mensualmente.
                </>
              </Typography>
            </Grid>

          </Grid>
          <Grid container className="card virtual-wallet">
            <Grid container className="icon" justifyContent="center">
              {benefitsIcons('virtual-wallet')}
            </Grid>
            <Grid container justifyContent="center" className="title">
              <Typography variant="paragraph-18" type="medium">
                Billetera Virtual
              </Typography>
            </Grid>
            <Grid container className="description">
              <Typography variant="paragraph-16">
                <>
                  Te otorgamos un CVU con nuestro Partnership y te fondeamos la cuenta para que inviertas en el FCI de Pagos Online.
                </>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid container className="nico-recomendation">
          <Grid container className="title" justifyContent="center">
            <Typography color={whiteColor} variant="paragraph-24" type="bold">
              Promueve la innovación
            </Typography>
          </Grid>
          <Grid container className="nico-img">
            <img src="https://cocheras-online-public.s3.amazonaws.com/landing/Mobile/PartnerShipNico.png" alt="nico-img" />
          </Grid>
        </Grid>
        <Grid container className="contact-us" justifyContent="center" ref={seccionReferinosRef}>
          <Grid justifyContent="center" container className="title">
            <Typography color={whiteColor} variant="paragraph-24" type="bold">
              Referí y ganá
            </Typography>
          </Grid>
          <Grid justifyContent="center" container className="description">
            <Typography color={secondaryColor2} variant="paragraph-16">
              <>
                Recomendá nuestros servicios <br /> y potencia los beneficios para ti <br /> y tus clientes.
              </>
            </Typography>
          </Grid>
          <Grid container className="contact-us-button">
            <ButtonLevel
              color="secondary"
              variant="contained"
              title="Contactanos"
              onClick={getMoreInfoWsp}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid container className="contact-footer">
        <Grid container className="contact">
          <Typography color={whiteColor} type="semibold" className="direction-contact" variant="paragraph-18">
            <>
              Av. Poeta Lugones 352, <br /> Nueva Córdoba, Córdoba Capital
            </>
          </Typography>
          <Grid container className="phone-and-mail">
            <Grid container>
              {icons.phone({ fill: whiteColor }, "outlined")}
              <button onClick={() => window.location.href = "tel:+5493512486992"}>
                <Typography color={whiteColor} type="semibold" className="phone-contact" variant="paragraph-18">
                  + 54 9 351 248-6992
                </Typography>
              </button>
            </Grid>
            <Grid container>
              {icons.mail({ width: 22, height: 18, color: whiteColor }, "outlined")}
              <button onClick={() => window.location.href = "mailto:soportecocherasonline@gmail.com"}>
                <Typography color={whiteColor} type="semibold" className="phone-contact" variant="paragraph-18">
                  soportecocherasonline@gmail.com
                </Typography>
              </button>
            </Grid>
          </Grid>
        </Grid>
        <Grid className="follow">
          <Typography color={whiteColor} variant="paragraph-22">
            Seguinos en redes
          </Typography>
          <Grid container spacing={2} className="networks" justifyContent="center" justifyItems="center">
            {/* <Grid item>
              <a href="https://www.linkedin.com/CocherasOnline" target="_blank" rel="noreferrer">
                {icons.linkedin({})}
              </a>
            </Grid> */}
            <Grid item>
              <a href="https://www.instagram.com/cocherasonline.pro" target="_blank" rel="noreferrer">
                {icons.instagram({ fill: "#4662F8", width: 28, height: 28 })}
              </a>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container className="footer">
        <Grid item xs={4} className="logo">
          <img
            src="https://cocheras-online-public.s3.amazonaws.com/landing/Mobile/footer-logo.png"
            alt="imagen"
          />
        </Grid>
        <Grid item xs={8} className="data">
          <Typography variant="paragraph-14" color={whiteColor}>
            Términos y condiciones
          </Typography>
          <Typography variant="paragraph-14" color={whiteColor}>
            Condiciones Generales de Uso
          </Typography>
          <Typography variant="paragraph-14" color={whiteColor}>
            Política de cookies
          </Typography>
        </Grid>
      </Grid>
      <Popper
        placement="bottom-end"
        className="popper"
        id="landing-mobile-popper"
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
      >
        <ClickAwayListener onClickAway={onClosePopper}>
          <Grid className="landing-mobile-options">
            <Grid className="item" container>
              <Typography variant="paragraph-14">
                <ButtonLevel
                  onClick={() => {
                    setMenuSelected(0)
                    handleScroll(seccionInicioRef)
                  }}
                  align="left"
                  variant="text"
                  title="Inicio"
                  color={menuSelected === 0 ? "secondary" : "none"}
                />
              </Typography>
            </Grid>
            <Grid className="item" container>
              <Typography variant="paragraph-14">
                <ButtonLevel
                  onClick={() => {
                    setMenuSelected(1)
                    handleScroll(seccionBeneficiosRef)
                  }}
                  align="left"
                  variant="text"
                  title="Nuestros beneficios"
                  color={menuSelected === 1 ? "secondary" : "none"}
                />
              </Typography>
            </Grid>
            <Grid className="item" container>
              <Typography variant="paragraph-14">
                <ButtonLevel
                  onClick={() => {
                    setMenuSelected(1)
                    handleScroll(seccionReferinosRef)
                  }}
                  align="left"
                  variant="text"
                  title="Contactanos"
                  color={menuSelected === 1 ? "secondary" : "none"}
                />
              </Typography>
            </Grid>
          </Grid>
        </ClickAwayListener>
      </Popper>
    </Grid>
  );
}

function partnerShipSVGicon() {
  return (
    <svg width="80" height="55" viewBox="0 0 64 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M31.4262 15.4835C29.8334 13.7071 29.9698 10.9108 32.1068 9.84982C33.5281 9.14413 35.0537 8.65034 36.6356 8.39144C39.648 7.89842 42.7386 8.27782 45.5423 9.48484C48.346 10.6919 50.7457 12.6761 52.4579 15.2031C54.1701 17.7301 55.1234 20.6944 55.2051 23.7458C55.2867 26.7972 54.4934 29.8082 52.9188 32.4232C51.3442 35.0382 49.0541 37.1479 46.319 38.5032C43.5839 39.8585 40.518 40.4027 37.4836 40.0715C35.8901 39.8976 34.3402 39.4861 32.8832 38.8575C30.6925 37.9123 30.4068 35.1273 31.9023 33.2683L31.989 33.1604C33.4366 31.361 36.1252 31.2319 38.4209 31.4825C39.816 31.6347 41.2255 31.3846 42.4829 30.7615C43.7403 30.1384 44.7932 29.1685 45.5171 27.9663C46.241 26.7641 46.6057 25.3798 46.5682 23.977C46.5306 22.5741 46.0924 21.2113 45.3052 20.0496C44.518 18.8878 43.4148 17.9756 42.1258 17.4207C40.8369 16.8658 39.416 16.6913 38.0311 16.918C35.752 17.291 33.0604 17.306 31.5187 15.5866L31.4262 15.4835Z" fill="url(#paint0_linear_429_957)" />
      <g style={{ mixBlendMode: "color-dodge" }}>
        <path d="M46.1238 33.5842C47.5353 35.5078 47.1261 38.2774 44.8956 39.1243C43.4121 39.6876 41.8455 40.0299 40.2459 40.1329C37.1997 40.3289 34.1611 39.6491 31.4888 38.1737C28.8166 36.6983 26.6224 34.489 25.1655 31.8066C23.7086 29.1243 23.0498 26.081 23.2669 23.0362C23.484 19.9915 24.5679 17.0725 26.3907 14.624C28.2135 12.1755 30.6989 10.2998 33.5535 9.2185C36.408 8.13718 39.5124 7.8954 42.4999 8.52172C44.0687 8.85061 45.5709 9.41167 46.9595 10.1798C49.0473 11.3346 49.0593 14.1343 47.3892 15.8381L47.2923 15.937C45.6757 17.5862 42.9874 17.4517 40.7271 16.9779C39.3537 16.6899 37.9265 16.8011 36.6141 17.2982C35.3018 17.7953 34.1591 18.6577 33.3211 19.7833C32.4831 20.909 31.9848 22.251 31.885 23.6508C31.7852 25.0505 32.0881 26.4496 32.7579 27.6828C33.4277 28.916 34.4364 29.9317 35.6649 30.61C36.8935 31.2883 38.2904 31.6008 39.6909 31.5107C41.9955 31.3623 44.6757 31.6107 46.0419 33.4726L46.1238 33.5842Z" fill="url(#paint1_linear_429_957)" />
      </g>
      <g style={{ mixBlendMode: "hard-light" }}>
        <path d="M29.755 33.5842C31.1665 35.5078 30.7573 38.2774 28.5269 39.1243C27.0433 39.6876 25.4767 40.0299 23.8771 40.1329C20.8309 40.3289 17.7923 39.6491 15.1201 38.1737C12.4478 36.6983 10.2537 34.489 8.79674 31.8066C7.33982 29.1243 6.681 26.081 6.89811 23.0362C7.11521 19.9915 8.19917 17.0725 10.0219 14.624C11.8447 12.1755 14.3302 10.2998 17.1847 9.2185C20.0392 8.13718 23.1436 7.8954 26.1311 8.52172C27.7 8.85061 29.2022 9.41167 30.5907 10.1798C32.6785 11.3346 32.6905 14.1343 31.0204 15.8381L30.9235 15.937C29.3069 17.5862 26.6186 17.4517 24.3584 16.9779C22.9849 16.6899 21.5577 16.8011 20.2454 17.2982C18.933 17.7953 17.7904 18.6577 16.9524 19.7833C16.1144 20.909 15.616 22.251 15.5162 23.6508C15.4164 25.0505 15.7193 26.4496 16.3891 27.6828C17.0589 28.916 18.0676 29.9317 19.2962 30.61C20.5247 31.2883 21.9217 31.6008 23.3221 31.5107C25.6267 31.3623 28.3069 31.6107 29.6731 33.4726L29.755 33.5842Z" fill="url(#paint2_linear_429_957)" />
      </g>
      <defs>
        <linearGradient id="paint0_linear_429_957" x1="27.3121" y1="34.8501" x2="65.8017" y2="0.773244" gradientUnits="userSpaceOnUse">
          <stop stopColor="#132462" />
          <stop offset="0.681164" stopColor="#4662F8" />
        </linearGradient>
        <linearGradient id="paint1_linear_429_957" x1="27.3121" y1="34.8501" x2="65.8017" y2="0.773244" gradientUnits="userSpaceOnUse">
          <stop stopColor="#132462" />
          <stop offset="0.681164" stopColor="#4662F8" />
        </linearGradient>
        <linearGradient id="paint2_linear_429_957" x1="10.9433" y1="34.8501" x2="49.433" y2="0.773244" gradientUnits="userSpaceOnUse">
          <stop stopColor="#132462" />
          <stop offset="0.681164" stopColor="#4662F8" />
        </linearGradient>
      </defs>
    </svg>

  )
}

function benefitsIcons(type: 'tiered-commissions' | 'virtual-wallet') {
  switch (type) {
    case 'tiered-commissions':
      return (
        <svg width="60" height="60" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="28" cy="27.7349" r="27.2726" fill="#4662F8" stroke="#4662f8" stroke-width="0.924496" />
          <path d="M18.25 31.792V33.7417C18.25 34.9552 18.25 35.5615 18.4862 36.025C18.6939 36.4327 19.0251 36.7648 19.4328 36.9725C19.8958 37.2084 20.5022 37.2084 21.7133 37.2084H37.7502M18.25 31.792V18.7917M18.25 31.792L22.428 28.3105C24.1982 26.8353 25.3618 26.987 26.8904 28.5156L26.8974 28.5226C28.5629 30.1881 29.7573 30.1186 31.4172 28.6663L37.75 23.1251" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      )
    case 'virtual-wallet':
      return (
        <svg width="60" height="60" viewBox="0 0 56 57" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="27.7349" cy="28.6895" r="27.2726" fill="#4662F8" stroke="#4662f8" stroke-width="0.924496" />
          <path d="M28 19.25C23.2135 19.25 19.3333 20.6468 19.3333 22.3699C19.3333 26.8767 36.6666 26.8767 36.6666 22.3699C36.6666 20.6468 32.7864 19.25 28 19.25Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M36.6666 28.7292C36.6666 33.7848 19.3333 33.7848 19.3333 28.7292" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M19.3333 22.5V35.6792C19.3333 39.7736 36.6666 39.7736 36.6666 35.6792V22.5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      )
  }
}