import { Grid, Typography as TypographyMaterial } from "@mui/material";
import "./Typography.scss";
import { ReactElement } from "react";

interface Props {
  className?: string;
  text?: string | number | ReactElement;
  color?: string; // EJ: "#8b3bd5" but you have to use VARIABLES.ts
  type?: "regular" | "medium" | "bold" | "semibold";
  variant?:
    | "h1"
    | "h2"
    | "paragraph-24"
    | "paragraph-22"
    | "paragraph-20"
    | "paragraph-18"
    | "paragraph-16"
    | "paragraph-14"
    | "paragraph-12"
    | "paragraph-10"
    | "table-head"
    | "table-content"
    | "button1"
    | "button2"
    | "heading"
    | "input1"
    | "input2";
  children?: ReactElement | string | number;
}

export default function Typography(props: Props) {
  const { variant = "", type = "regular", className = "" } = props;
  return (
    <Grid className={`typography ${className}`}>
      <TypographyMaterial
        className={`typography-variant-${variant} typography-type-${type}`}
        style={{ color: props.color }}
      >
        {props.text || props.children}
      </TypographyMaterial>
    </Grid>
  );
}
