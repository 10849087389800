import axios from "axios";
import { API_URL } from "../../config/env";
import { getToken } from "../utils/token";
import {
  PaginatedResponse,
  PostResponse,
  defaultPaginatedResponse,
} from "../../utils/generalServiceResponses";

export enum CheckoutShift {
  MORNING = "Morning",
  AFTERNOON = "Afternoon",
  NIGHT = "Night",
  EXTRA = "Extra",
}

export function checkoutShiftToEs(shift: CheckoutShift): string {
  switch (shift) {
    case CheckoutShift.MORNING:
      return "Mañana";
    case CheckoutShift.AFTERNOON:
      return "Tarde";
    case CheckoutShift.NIGHT:
      return "Noche";

    default:
      return "-";
  }
}

export type Checkout = {
  id: number;
  shift: CheckoutShift;
  total: number;
  totalInCash: number;
  totalInTransfer: number;
  totalInDebit: number;
  totalOutcome: number;
  initialAmount: number;
  finalAmount: number;
  openedAt: string;
  closedAt: string;
};

export async function getCheckoutData(): Promise<Checkout | null> {
  const token = await getToken();

  try {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios.get(API_URL + `/checkout`, {
      headers,
    });
    return response.data;
  } catch (e) {
    console.warn(e);
    return null;
  }
}

export async function getCheckoutDataHistory(): Promise<
  PaginatedResponse<Checkout>
> {
  const token = await getToken();

  try {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios.get(API_URL + `/checkout/history`, {
      headers,
    });
    return response.data;
  } catch (e) {
    console.warn(e);
    return defaultPaginatedResponse;
  }
}

export type CheckoutDTO = {
  shift: CheckoutShift;
  pin: string;
};

export async function openCheckout(checkoutDTO: CheckoutDTO) {
  const token = await getToken();

  try {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios.post(API_URL + `/checkout`, checkoutDTO, {
      headers,
    });
    return response.data;
  } catch (e) {
    console.warn(e);
    return null;
  }
}

export async function validatePin(pin: string): Promise<{ isValid: boolean }> {
  const token = await getToken();

  try {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios.get(
      API_URL + `/checkout/validate-pin?pin=${pin}`,
      {
        headers,
      }
    );
    return response.data;
  } catch (e) {
    console.warn(e);
    return { isValid: false };
  }
}

export async function closeCheckout(): Promise<PostResponse<void>> {
  const token = await getToken();

  try {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios.post(
      API_URL + `/checkout/close`,
      {},
      {
        headers,
      }
    );
    return response;
  } catch (error: any) {
    return error.response;
  }
}
