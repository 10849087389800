import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { Divider, Grid } from "@mui/material";
import "./Modal.scss";
import ButtonLevel, { ButtonLevelColor } from "../Buttons";

interface Props {
  disabled?: boolean;
  open: boolean;
  disabledClose?: boolean;
  children?: React.ReactElement;
  fullOpacity?: boolean;
  buttons: {
    save?: {
      onClick: () => void;
      title?: string;
      color?: ButtonLevelColor
    };
    cancel?: {
      onClick: () => void;
      title?: string;
      color?: ButtonLevelColor
    };
  };
}

export default function BasicModal(props: Props) {
  return (
    <Grid container className="BasicModal">
      <Modal
        className={`material-basic-modal ${props.fullOpacity ? "full-opacity" : ""} ${props.disabledClose ? 'disabled-close' : ''}`}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={props.open}
        onClose={(event, reason) => {
          if (reason === "backdropClick" && props.disabledClose) {
            return; // No cerramos el modal si se hace clic fuera
          }
          props.buttons.cancel?.onClick();
        }}
        closeAfterTransition
      >
        <Fade in={props.open}>
          <Grid className="basic-modal" container>
            <Box className="box-modal">
              <Grid className="children" container>{props.children}</Grid>
              <Grid container className="button-modal">
                {props.disabledClose ? (
                  <Grid container>
                    <ButtonLevel
                      disabled={props.disabled}
                      size="large"
                      onClick={props.buttons.save?.onClick}
                      title={props.buttons.save?.title || "Guardar"}
                    />
                  </Grid>
                ) : (
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <ButtonLevel
                        size="large"
                        onClick={props.buttons.cancel?.onClick}
                        variant="outlined"
                        title={props.buttons.cancel?.title || "Cancelar"}
                        color={props.buttons.cancel?.color}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <ButtonLevel
                        disabled={props.disabled}
                        size="large"
                        onClick={props.buttons.save?.onClick}
                        title={props.buttons.save?.title || "Guardar"}
                        color={props.buttons.save?.color}
                      />
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Box>
          </Grid>
        </Fade>
      </Modal>
    </Grid>
  );
}
