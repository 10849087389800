import { Grid } from "@mui/material";
import MenuTop from "../../components/MenuTop";
import "./AccountStatus.scss";
import { useEffect, useRef, useState } from "react";
import BasicTable from "../../components/kit/BasicTable";
import {
  PaginatedResponse,
  defaultPaginateOptions,
  defaultPaginatedResponse,
} from "../../utils/generalServiceResponses";
import {
  Account,
  AccountStatusResultData,
  getCurrentAccount,
} from "../../services/accountStatus";
import { getAccountStatusGroupingByDate } from "../../services/accountStatus";
import { dateFormatter, numericFormatter } from "../../utils/formatters";
import Typography from "../../components/kit/Typography";
import icons from "../../utils/icons";
import Spinner from "../../components/kit/Spinner";
import { greenColor2, redColor2 } from "../../utils/VARIABLES";
import FilterModal from "../../components/AccountStatus/FilterModal";
import { getMovementsGroupedByDateBuffer } from "../../services/movements";

export default function AccountStatusPage() {
  const [status, setStatus] = useState<"loading" | "success" | "error">(
    "loading"
  );
  const [paginateOptions, setPaginateOptions] = useState(
    defaultPaginateOptions
  );

  const [account, setAccount] = useState<Account | null>(null);
  const [accountStatusResultData, setAccountStatusResultData] = useState<
    PaginatedResponse<AccountStatusResultData>
  >(defaultPaginatedResponse);

  // Filter modal variables
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [dateFrom, setDateFrom] = useState<string | null>(null);
  const [dateTo, setDateTo] = useState<string | null>(null);

  const handleGetAccountStatusGroupingByDate = () => {
    setStatus("loading");
    (async () => {
      const data = await getAccountStatusGroupingByDate(
        paginateOptions,
        dateFrom || undefined,
        dateTo || undefined
      );
      if (data !== undefined) {
        setAccountStatusResultData(data);
        setStatus("success");
      } else {
        setStatus("error");
      }
    })();
  };
  useEffect(handleGetAccountStatusGroupingByDate, [dateFrom, dateTo]);

  const handleGetCurrentAccount = () => {
    (async () => {
      const data = await getCurrentAccount();
      if (data !== undefined) {
        setAccount(data);
        setStatus("success");
      } else {
        setStatus("error");
      }
    })();
  };
  useEffect(handleGetCurrentAccount, []);

  const timeoutIdRef = useRef<NodeJS.Timeout | null>(null);
  useEffect(() => {
    // clear the timeout and prevent the callback from being called
    if (timeoutIdRef.current !== null) clearTimeout(timeoutIdRef.current);

    timeoutIdRef.current = setTimeout(
      handleGetAccountStatusGroupingByDate,
      500
    );
  }, [paginateOptions.page, paginateOptions.limit]);

  const isEmptyState = () => accountStatusResultData.data.length === 0;

  const getAmountType = (amount?: number) => {
    if (!amount) return "neutral";
    if (amount > 0) {
      return "income";
    } else if (amount < 0) {
      return "outcome";
    }
  };

  // Function to download account status as excel (.xslx)
  const handleGetAccountStatusBufferAndDownloadIt = () => {
    (async () => {
      const response = await getMovementsGroupedByDateBuffer();

      if (response) {
        const uint8Array = new Uint8Array(response.data); // response.data is a Uint8Array or array of bytes

        const blob = new Blob([uint8Array]);
        const url = window.URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `Estado de cuenta ${dateFormatter(new Date().toDateString())}.xlsx`
        );

        document.body.appendChild(link);
        link.click();
      }

      return;
    })();
  };

  return (
    <Grid container className="account-status">
      {status === "loading" && (
        <Grid container className="account-status-dashboard-spinner-loading">
          <Spinner />
        </Grid>
      )}
      {status === "success" && (
        <Grid container className="account-status-content">
          <Grid container className="account-status-cards">
            <Grid container className="account-status-card balance">
              <Grid container>
                {icons.simpleCash()}
                <Typography
                  type="semibold"
                  variant="paragraph-16"
                  text="Dinero en cuenta"
                />
              </Grid>
              <Typography
                className={`balance-amount type-${getAmountType(
                  account?.amount
                )}`}
                type="semibold"
                variant="paragraph-20"
                text={numericFormatter(account?.amount || 0)}
              />
            </Grid>
            <Grid container className="account-status-card income">
              <Grid container>
                {icons.cashCheck()}
                <Typography
                  type="semibold"
                  variant="paragraph-16"
                  text="Total de ingresos"
                />
              </Grid>
              <Typography
                className={`income-amount type-${getAmountType(
                  account?.amountIncome
                )}`}
                type="semibold"
                variant="paragraph-20"
                text={numericFormatter(account?.amountIncome || 0)}
              />
            </Grid>
            <Grid container className="account-status-card outcome">
              <Grid container>
                {icons.cashRemove()}
                <Typography
                  type="semibold"
                  variant="paragraph-16"
                  text="Total de egresos"
                />
              </Grid>
              <Typography
                className={`outcome-amount type-${getAmountType(
                  account?.amountOutcome
                )}`}
                type="semibold"
                variant="paragraph-20"
                text={numericFormatter(Math.abs(account?.amountOutcome || 0))}
              />
            </Grid>
          </Grid>
          {status === "success" && (
            <Grid container>
              <BasicTable
                noFoundMessage={{
                  title: "Historial de cuenta",
                  description: "En esta sección vas a poder ver el balance histórico de dinero que tienes en tu cuenta"
                }}
                status="success"
                head="Estado de cuenta"
                columns={[
                  "Fecha",
                  "Listado de ingreso",
                  "Listado de egreso",
                  "Recaudación total",
                ]}
                rows={accountStatusResultData.data.map(
                  (asr: AccountStatusResultData) => {
                    return [
                      dateFormatter(asr.date),
                      <Typography
                        className="account-status-table-content-income"
                        variant="table-content"
                        text={numericFormatter(asr.income)}
                      />,
                      <Typography
                        className="account-status-table-content-outcome"
                        variant="table-content"
                        text={numericFormatter(Math.abs(asr.outcome))}
                      />,
                      <Typography
                        className="account-status-table-content-balance"
                        variant="table-content"
                        color={asr.amount > 0 ? greenColor2 : redColor2}
                        text={numericFormatter(Math.abs(asr.amount))}
                      />,
                    ];
                  }
                )}
                headOptions={[
                  {
                    label: "Filtrar por mes",
                    onClick: () => setOpenFilterModal(true),
                  },
                  {
                    label: "Descargar reporte",
                    onClick: () => handleGetAccountStatusBufferAndDownloadIt(),
                  },
                ]}
                paginateOptions={{
                  currentPage: paginateOptions.page,
                  rowsPerPage: paginateOptions.limit,
                  setCurrentPage: (page: number) => {
                    setPaginateOptions({ ...paginateOptions, page });
                  },
                  totalItems: accountStatusResultData.totalItems,
                }}
              />
            </Grid>
          )}
          <FilterModal
            closeModal={() => setOpenFilterModal(false)}
            open={openFilterModal}
            dateFrom={dateFrom}
            dateTo={dateTo}
            onConfirm={(dateFrom: string, dateTo: string) => {
              setDateFrom(dateFrom);
              setDateTo(dateTo);
            }}
          />
        </Grid>
      )}
    </Grid>
  );
}
