import { Grid } from "@mui/material";
import "./UpdateCustomerDrawer.scss";
import { useState } from "react";
import { DrawerStatus, SideDrawer } from "../../kit/SideDrawer";
import { Customer, CustomerUpdateDTO, updateCustomer } from "../../../services/customers";
import TextInput from "../../kit/Inputs/Text";
import NumberInput from "../../kit/Inputs/Number";

interface Props {
  setCloseDrawer: () => void;
  openDrawer: boolean;
  updatePage: () => void;
  title: string;
  customer: Customer;
}

export default function UpdateCustomerDrawer(props: Props) {
  const defaultCustomer = {
    id: props.customer.id,
    firstName: props.customer.firstName,
    lastName: props.customer.lastName,
    document: props.customer.document,
    address: props.customer.address,
    email: props.customer.email,
    phone: props.customer.phone,
  }

  const [customerDTO, setCustomerDTO] =
    useState<CustomerUpdateDTO>(defaultCustomer);
  const [drawerStatus, setDrawerStatus] = useState<DrawerStatus>("idle");

  const handleUpdateCustomer = () => {
    setDrawerStatus("loading");
    (async () => {
      let response = await updateCustomer(customerDTO);

      if (response.status === 200) {
        props.updatePage();
        setDrawerStatus("success");
      } else {
        setDrawerStatus("error");
      }
    })();
  };

  const onCloseModal = () => {
    props.setCloseDrawer();
    setCustomerDTO(defaultCustomer);
    setDrawerStatus('idle');
  };

  const disableCreateCustomer = () => {
    if (
      !customerDTO.firstName || !customerDTO.lastName || !customerDTO.document || customerDTO.document.length < 7
    ) return true;
    return false;
  };

  return (
    <SideDrawer
      drawerStatus={drawerStatus}
      onRetry={() => setDrawerStatus("idle")}
      title={props.title}
      disabled={disableCreateCustomer()}
      saveOnclick={handleUpdateCustomer}
      closeDrawer={onCloseModal}
      open={props.openDrawer}
      saveLabel="Guardar"
    >
      <Grid className="create-customer-drawer" container>
        <Grid container className="input-drawer">
          <TextInput
            isRequired
            value={customerDTO.firstName}
            placeholder="Nombre del cliente"
            onChange={(firstName) =>
              setCustomerDTO((prevState) => ({
                ...prevState,
                firstName,
              }))
            }
            label="Nombre"
          />
        </Grid>
        <Grid container className="input-drawer">
          <TextInput
            isRequired
            value={customerDTO.lastName}
            placeholder="Apellido del cliente"
            onChange={(lastName) =>
              setCustomerDTO((prevState) => ({
                ...prevState,
                lastName,
              }))
            }
            label="Apellido"
          />
        </Grid>
        <Grid container className="input-drawer">
          <NumberInput
            isRequired
            value={customerDTO.document || props.customer.document}
            placeholder="Documento del cliente"
            onChange={(document) =>
              setCustomerDTO((prevState) => ({
                ...prevState,
                document: document.toString(),
              }))
            }
            label="Documento"
          />
        </Grid>
        <Grid container className="input-drawer">
          <TextInput
            value={customerDTO.address}
            placeholder="Dirección del cliente"
            onChange={(address) =>
              setCustomerDTO((prevState) => ({
                ...prevState,
                address,
              }))
            }
            label="Dirección"
          />
        </Grid>
        <Grid container className="input-drawer">
          <TextInput
            value={customerDTO.email}
            placeholder="Email del cliente"
            onChange={(email) =>
              setCustomerDTO((prevState) => ({
                ...prevState,
                email,
              }))
            }
            label="Email"
          />
        </Grid>
        <Grid container className="input-drawer">
          <TextInput
            value={customerDTO.phone}
            placeholder="Télefono del cliente"
            onChange={(phone) =>
              setCustomerDTO((prevState) => ({
                ...prevState,
                phone,
              }))
            }
            label="Télefono"
          />
        </Grid>
      </Grid>
    </SideDrawer>
  );
}
