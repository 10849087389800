import { Grid } from "@mui/material";
import Typography from "../../components/kit/Typography";
import "./ContactUs.scss";
import CardContact from "../../components/kit/Cards/CardTag";

export default function ContactUsPage() {
  return (
    <Grid className="contact-us-page" container>
      <Grid container className="contact-us-title">
        <Typography type="semibold" variant="h1">
          <>
            La gestión de cocheras hecha simple. <br></br> Contáctanos para
            habilitar tu cuenta.
          </>
        </Typography>
      </Grid>

      <Grid className="simple-cards" spacing={2} container>
       
      </Grid>
    </Grid>
  );
}
