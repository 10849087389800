import { Grid, List } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import './CustomerPage.scss';
import { Customer, deleteCustomer, getAllCustomers } from "../../../services/customers";
import BasicTable, { BasicTableStatus } from "../../kit/BasicTable";
import { defaultPaginatedResponse, defaultPaginateOptions, PaginatedResponse } from "../../../utils/generalServiceResponses";
import Alert, { AlertType } from "../../kit/Alert";
import ButtonLevel from "../../kit/Buttons";
import Typography from "../../kit/Typography";
import { numericFormatter } from "../../../utils/formatters";
import CreateCustomerDrawer from "../CreateCustomerDrawer";
import UpdateCustomerDrawer from "../UpdateCustomerDrawer";
import ConfirmationModal from "../../kit/Modal/ConfirmationModal";
import { CollectCustomerDrawer } from "../CollectCustomerDrawer";

export function CustomerPage() {
  const [openCreateCustomerDrawer, setOpenCreateCustomerDrawer] = useState(false);
  const [customerToDeleteModal, setCustomerToDeleteModal] = useState<number>(0);
  const [deleteCustomerStatus, setDeleteCustomerStatus] = useState<
    "idle" | "loading" | "success" | "error"
  >("idle");
  const [customerToUpdate, setCustomerToUpdate] = useState<Customer | null>(null);
  const [alertStatusDelete, setAlertStatusDelete] =
    useState<AlertType>("close");

  const [status, setStatus] = useState<BasicTableStatus>(
    "loading"
  );
  const [paginateOptions, setPaginateOptions] = useState(
    defaultPaginateOptions
  );
  const [customerPaginated, setCustomerPaginated] = useState<
    PaginatedResponse<Customer>
  >(defaultPaginatedResponse);
  const [openCollectCustomerDrawer, setOpenCollectCustomerDrawer] = useState<Customer | null>(null);

  const handleGetAllCustomers = () => {
    setStatus("loading");
    (async () => {
      const data = await getAllCustomers(paginateOptions);
      if (data !== undefined) {
        setCustomerPaginated(data);
        setStatus("success");
      } else {
        setStatus("error");
      }
    })();
  };

  const handleDeletedCustomer = (id: number) => {
    setDeleteCustomerStatus("loading");
    (async () => {
      const data = await deleteCustomer(id);
      if (data.status === 200) {
        handleGetAllCustomers();
        setAlertStatusDelete("success");
        setDeleteCustomerStatus("success");
      } else {
        setAlertStatusDelete("error");
        setDeleteCustomerStatus("error");
      }

      setCustomerToDeleteModal(0);
    })();
  };

  const timeoutIdRef = useRef<NodeJS.Timeout | null>(null);
  useEffect(() => {
    // clear the timeout and prevent the callback from being called
    if (timeoutIdRef.current !== null) clearTimeout(timeoutIdRef.current);

    timeoutIdRef.current = setTimeout(handleGetAllCustomers, 300);
  }, [
    paginateOptions.page,
    paginateOptions.limit,
    paginateOptions.search,
    paginateOptions,
  ]);

  return (
    <Grid container className="customer-page">
      <Grid container className="customer-content">
        <BasicTable
          rightElement={
            <ButtonLevel
              fillWidth={false}
              title="Agregá un cliente"
              size="medium"
              onClick={() => setOpenCreateCustomerDrawer(true)}
              variant="contained"
            />
          }
          status={status}
          head="Clientes"
          search={{
            value: paginateOptions.search,
            onChange: (value) => {
              if (value === "") setStatus("loading");
              setPaginateOptions({ ...paginateOptions, search: value });
            },
            placeholder: "Buscar",
          }}
          columns={[
            "Id", "Cliente", "Documento", "Télefono", "Productos", "Total adeudado"
          ]}
          rows={customerPaginated.data.map((customer: Customer) => ([
            customer.id,
            `${customer.firstName} ${customer.lastName}`,
            customer.document,
            customer.phone || "-",
            (
              <List
                sx={{
                  width: '100%',
                  maxWidth: 360,
                  bgcolor: 'background.paper',
                  position: 'relative',
                  overflow: 'auto',
                  maxHeight: 300,
                  '& ul': { padding: 0 },
                }}
                subheader={<li />}
              >
                {customer.products.map(cp => (
                  <Grid container alignItems="center">
                    <Grid item>
                      <FiberManualRecordIcon
                        sx={{ width: "10px", marginTop: "5px", marginRight: "5px" }} />
                    </Grid>
                    <Grid item>
                      <Typography variant="paragraph-12">
                        {`${cp.productName}: ${cp.quantity}${(cp.productType === 'unit' ? 'un.' : 'gr.')} - ${numericFormatter(cp.productAmount)}`}
                      </Typography>
                    </Grid>
                  </Grid>
                ))}
              </List>
            ),
            numericFormatter(customer.totalAmount)
          ]))}
          options={[
            {
              label: "Cobrar",
              onClick: (rowIndex: number) => {
                const customer = customerPaginated.data[rowIndex];
                setOpenCollectCustomerDrawer(customer);
              },
              show: (rowIndex: number) => {
                const customer = customerPaginated.data[rowIndex];

                return customer.totalAmount > 0
              },
            },
            {
              label: "Enviar ticket",
              onClick: (rowIndex: number) => {
                const customer = customerPaginated.data[rowIndex];
                window.location.href = "?ticketId=" + customer.id;
              },
              show: (rowIndex: number) => {
                const customer = customerPaginated.data[rowIndex];

                return customer.totalAmount > 0
              },
            },
            {
              label: "Editar",
              onClick: (rowIndex: number) => {
                const customer = customerPaginated.data[rowIndex];
                setCustomerToUpdate(customer);
              },
            },
            {
              label: "Eliminar",
              onClick: (rowIndex: number) => {
                const customer = customerPaginated.data[rowIndex];
                setCustomerToDeleteModal(customer.id);
              },
            },
          ]}
          paginateOptions={{
            currentPage: paginateOptions.page,
            rowsPerPage: paginateOptions.limit,
            setCurrentPage: (page: number) => {
              setPaginateOptions({ ...paginateOptions, page });
            },
            totalItems: customerPaginated.totalItems,
          }}
        />
      </Grid>
      <CreateCustomerDrawer
        title="Agregá un cliente"
        setOpenDrawer={setOpenCreateCustomerDrawer}
        openDrawer={openCreateCustomerDrawer}
        updatePage={handleGetAllCustomers}
      />
      {openCollectCustomerDrawer !== null && (
        <CollectCustomerDrawer
          customer={openCollectCustomerDrawer}
          open={!!openCollectCustomerDrawer}
          closeDrawer={() => setOpenCollectCustomerDrawer(null)}
          updatePage={handleGetAllCustomers}
        />
      )}
      {customerToUpdate !== null && (
        <UpdateCustomerDrawer
          title="Actualizá el cliente"
          setCloseDrawer={() => setCustomerToUpdate(null)}
          openDrawer={!!customerToUpdate}
          updatePage={handleGetAllCustomers}
          customer={customerToUpdate}
        />
      )}
      <ConfirmationModal
        disabled={deleteCustomerStatus === "loading"}
        confirmationOption={{
          onclick: () => handleDeletedCustomer(customerToDeleteModal),
          title: "Eliminar",
          color: "error",
        }}
        title="Eliminar Cliente"
        description="Este cliente se eliminará permanentemente.
          ¿Está seguro que desea continuar?"
        closeModal={() => setCustomerToDeleteModal(0)}
        open={!!customerToDeleteModal}
      />
      {alertStatusDelete !== "close" && (
        <Alert
          setAlertStatus={setAlertStatusDelete}
          severity={alertStatusDelete}
          message={
            alertStatusDelete === "success"
              ? "El cliente se a eliminado con exíto"
              : "Ocurrío un error"
          }
        />
      )}
    </Grid>
  )
}