import { Grid, Tooltip as TooltipMaterial } from "@mui/material";
import Typography from "../Typography";

import "./Tooltip.scss";

interface Props {
  children: React.ReactElement;
  title: string;
  followCursor?: boolean;
  placement?:
    | "bottom-end"
    | "bottom-start"
    | "bottom"
    | "left-end"
    | "left-start"
    | "left"
    | "right-end"
    | "right-start"
    | "right"
    | "top-end"
    | "top-start"
    | "top";
}
export default function Tooltip(props: Props) {
  const { followCursor = false, title, placement = "bottom" } = props;

  return (
    <Grid container className="tooltip">
      <TooltipMaterial
        placement={placement}
        followCursor={followCursor}
        arrow
        title={<Typography variant="paragraph-14" text={title} />}
      >
        {props.children}
      </TooltipMaterial>
    </Grid>
  );
}
