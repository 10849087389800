import { Grid } from "@mui/material";
import "./MenuTop.scss";
import React, { ReactNode } from "react";
import Typography from "../kit/Typography";

interface Props {
  title: string;
  rightElement?: ReactNode;
}

export default function MenuTop(props: Props) {
  const { rightElement } = props;
  return (
    <Grid className="menu-top-content">
      <Grid item xs={9} className="menu-title">
        <Typography type="semibold" variant="h2" text={props.title} />
      </Grid>
      <Grid className="button-menu" item xs={3}>
        {rightElement}
      </Grid>
    </Grid>
  );
}
