import { Divider, Grid } from "@mui/material";
import { deleteTag, patchUpdateTag, postNewTag, Tag, TagDTO, UpdateTagDTO } from "../../../services/tags";
import { useState } from "react";
import { DrawerStatus, SideDrawer } from "../../kit/SideDrawer";
import TextInput from "../../kit/Inputs/Text";
import MultilineInput from "../../kit/Inputs/Multiline";

import './TagDrawerOptions.scss';
import SelectInput from "../../kit/Inputs/Select";


interface Props {
  setOpenDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  openDrawer: boolean;
  tags: Tag[];
  updatePage: () => void;
}

const defaultTagDto = {
  name: "",
  description: ""
}

export function TagDrawerOptions(props: Props) {
  const [action, setAction] = useState<'create' | 'edit' | 'delete'>('create');

  const [tagDTO, setTagDTO] =
    useState<TagDTO>(defaultTagDto);
  const [updateTagDTO, setUpdateTagDTO] =
    useState<UpdateTagDTO>({ id: 0, name: "", description: null });
  const [deleteTagId, setDeleteTagId] =
    useState<number>(0);

  const [drawerStatus, setDrawerStatus] = useState<DrawerStatus>("idle");

  const handlePostTag = () => {
    (async () => {
      let response = await postNewTag(tagDTO);
      if (response) {
        props.updatePage();
        setDrawerStatus("success");
        setTagDTO(defaultTagDto);
      } else {
        setDrawerStatus("error");
      }
    })();
  };

  const handleUpdateTag = () => {
    (async () => {
      let response = await patchUpdateTag(updateTagDTO);
      if (response.status === 200) {
        props.updatePage();
        setDrawerStatus("success");
        setTagDTO(defaultTagDto);
      } else {
        setDrawerStatus("error");
      }
    })();
  };

  const handleDeleteTag = () => {
    (async () => {
      let response = await deleteTag(deleteTagId);
      if (response.status === 200) {
        props.updatePage();
        setDrawerStatus("success");
        setTagDTO(defaultTagDto);
      } else {
        setDrawerStatus("error");
      }
    })();
  };

  const disable = () => {
    if (action === 'create' && (!tagDTO.name || tagDTO.name === "")) return true;
    if (action === 'edit' && (!updateTagDTO.id || !updateTagDTO.name)) return true;
    if (action === 'delete' && (!deleteTagId)) return true;
    return false;
  };

  return (
    <SideDrawer
      title="Opciones sobre etiquetas"
      disabled={disable()}
      saveOnclick={action === 'create' ? handlePostTag : action === 'edit' ? handleUpdateTag : handleDeleteTag}
      saveLabel="Guardar"
      closeDrawer={() => {
        setDrawerStatus("idle");
        props.setOpenDrawer(false);
      }}
      open={props.openDrawer}
      drawerStatus={drawerStatus}
    >
      <Grid container className="tag-drawer-options">
        <Grid container className="select-action">
          <SelectInput
            itemSelected={action}
            onChange={(value) => setAction(value)}
            label="Acción"
            items={[
              { label: 'Crear nuevo', value: "create" },
              { label: 'Editar', value: "edit" },
              { label: 'Eliminar', value: "delete" },
            ]}
          />
        </Grid>
        <Divider sx={{ width: "100%", marginBottom: "2rem" }} variant="fullWidth" />
        {action === 'create' && (
          <Grid className="create-tag-drawer" container>
            <Grid className="create-tag-drawer-item" container>
              <TextInput
                isRequired
                onChange={(name) =>
                  setTagDTO((prevState) => ({
                    ...prevState,
                    name,
                  }))
                }
                label="Nombre"
              />
            </Grid>
            <Grid className="create-tag-drawer-item" container>
              <MultilineInput
                label="Descripción"
                value={tagDTO.description || ""}
                rows={5}
                onChange={(description) =>
                  setTagDTO((prevState) => ({
                    ...prevState,
                    description,
                  }))
                }
              />
            </Grid>
          </Grid>
        )}
        {action === 'edit' && (
          <Grid className="edit-tag-drawer" container>
            <Grid className="edit-tag-drawer-item" container>
              <SelectInput
                itemSelected={updateTagDTO.id}
                onChange={(value) => {
                  const tagToUpdate = props.tags.find(tag => tag.id === value);
                  if (tagToUpdate)
                    setUpdateTagDTO({ id: tagToUpdate.id, description: tagToUpdate.description, name: tagToUpdate.name })
                }}
                label="Etiqueta a actualizar"
                items={props.tags.map(tag => ({
                  value: tag.id,
                  label: tag.name
                }))}
              />
            </Grid>

            <Grid className="edit-tag-drawer-item" container>
              <TextInput
                isRequired
                value={updateTagDTO.name}
                onChange={(name) =>
                  setUpdateTagDTO((prevState) => ({
                    ...prevState,
                    name,
                  }))
                }
                label="Nombre"
              />
            </Grid>
            <Grid className="edit-tag-drawer-item" container>
              <MultilineInput
                label="Descripción"
                value={updateTagDTO.description || ""}
                rows={5}
                onChange={(description) =>
                  setUpdateTagDTO((prevState) => ({
                    ...prevState,
                    description,
                  }))
                }
              />
            </Grid>
          </Grid>
        )}
        {action === 'delete' && (
          <Grid className="delete-tag-drawer" container>
            <Grid className="delete-tag-drawer-item" container>
              <SelectInput
                itemSelected={deleteTagId}
                onChange={(value) => {
                  const tagToDelete = props.tags.find(tag => tag.id === value);
                  if (tagToDelete)
                    setDeleteTagId(tagToDelete.id)
                }}
                label="Etiqueta a eliminar"
                items={props.tags.map(tag => ({
                  value: tag.id,
                  label: tag.name
                }))}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
    </SideDrawer>
  )
}