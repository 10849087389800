
export async function getToken() {
  const token = localStorage.getItem("access_token");
  if(token) {
    return token;
  }

  // wait for token to be set
  return new Promise((resolve) => {
    const interval = setInterval(() => {
      const token = localStorage.getItem("access_token");
      if(token) {
        clearInterval(interval);
        resolve(token);
      }
    }, 100);
  });
}