import { Grid } from "@mui/material";
import "./Spinner.scss";
import SyncLoader from "react-spinners/SyncLoader";
import { primaryColor1 } from "../../../utils/VARIABLES";

export default function Spinner() {
  return (
    <Grid className="Spinner" container>
      <SyncLoader color={primaryColor1} />
    </Grid>
  );
}
