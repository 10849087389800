import { Button, Grid } from "@mui/material";
import "./CartPayDrawer.scss";
import { useEffect, useState } from "react";
import moment from "moment";
import icons from "../../../utils/icons";
import { grayColor, primaryColor2, yellowColor3 } from "../../../utils/VARIABLES";
import { primaryColor1 } from "../../../utils/VARIABLES";
import { numericFormatter } from "../../../utils/formatters";
import { AlertType } from "../../kit/Alert";
import { DrawerStatus, SideDrawer } from "../../kit/SideDrawer";
import Typography from "../../kit/Typography";
import NumberInput from "../../kit/Inputs/Number";
import Checkbox from "../../kit/Selectors/Checkbox";
import { Cart, collectCart, CollectCartDTO } from "../../../services/cart";
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import ButtonLevel from "../../kit/Buttons";
import SelectInput from "../../kit/Inputs/Select";
import { addProductForCustomerBatch, AddProductForCustomerBatchDTO, AddProductForCustomerDTO, getAllCustomers } from "../../../services/customers";
import CreateCustomerDrawer from "../../Customer/CreateCustomerDrawer";

interface Props {
  cart: Cart;
  setOpenModal: React.Dispatch<React.SetStateAction<Cart | null>>;
  updatePage: () => void;
  setAlertStatus: (value: AlertType) => void;
  label?: string;
}

const defaultCollectCartDTO = (cart: Cart): CollectCartDTO => {
  return {
    paymentMethod: "Cash",
    changeReturned: true,
    amountPaid: null,
    collectedDate: moment().format("YYYY-MM-DD HH:mm")
  }
};

export default function CartPayDrawer(props: Props) {
  const [drawerStatus, setDrawerStatus] = useState<DrawerStatus>("idle");
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<"Cash" | "Card" | "Transfer" | "Customer">('Cash');
  const [collectCartDTO, setCollectCartDTO] =
    useState<CollectCartDTO>(defaultCollectCartDTO(props.cart));

  const [customerOptions, setCustomerOptions] = useState<{
    value: number,
    label: string
  }[]>([]);
  const [customerSelected, setCustomerSelected] = useState<number | null>(null);
  const [openCreateCustomerDrawer, setOpenCreateCustomerDrawer] = useState(false);


  const minAmountToPay = props.cart.totalAmount - 49;
  const disabled =
    drawerStatus === "loading" ||
    (!!collectCartDTO.amountPaid && collectCartDTO.amountPaid < minAmountToPay);

  const handleCollectCart = () => {
    setDrawerStatus("loading");
    (async () => {
      if (collectCartDTO.amountPaid === null) collectCartDTO.amountPaid = props.cart.totalAmount;
      const response = await collectCart(collectCartDTO);
      if (response.status === 201) {
        props.updatePage();
        setDrawerStatus("success");
      } else {
        setDrawerStatus("error");
      }
    })();
  };

  const handleGetAllCustomers = () => {
    (async () => {
      const response = await getAllCustomers();
      if (response.data) {
        setCustomerOptions(response.data.map(customer => ({
          label: `${customer.firstName} ${customer.lastName}`,
          value: customer.id
        })))
      }
    })();
  }
  useEffect(handleGetAllCustomers, []);

  const handleAddProductForCustomerBatch = () => {
    if (!customerSelected) return;
    const dto: AddProductForCustomerBatchDTO = { batch: [] };

    for (const cartProduct of props.cart.cartProducts) {
      const product = cartProduct.product;

      const newElement: AddProductForCustomerDTO = {
        customerId: customerSelected,
        productId: product.id,
        quantity: cartProduct.quantity
      }

      dto.batch.push(newElement);
    }

    (async () => {
      const response = await addProductForCustomerBatch(dto);
      if (response.status === 201) {
        props.updatePage();
        setDrawerStatus("success");
      } else {
        setDrawerStatus("error");
      }
    })();
  }

  return (
    <Grid container>
      <SideDrawer
        disabled={disabled}
        drawerStatus={drawerStatus}
        title="Cobrar carrito"
        saveOnclick={selectedPaymentMethod === 'Customer' ? handleAddProductForCustomerBatch : handleCollectCart}
        saveLabel={selectedPaymentMethod === 'Customer' ? 'Fiar' : 'Cobrar'}
        closeDrawer={() => props.setOpenModal(null)}
        open={props.cart !== null}
      >
        <Grid container className="collect-cart-drawer">
          <Grid className="box-payment-methods" container>
            <Grid className="payment-methods-cash" item xs={3} justifyContent="center">
              <Button
                style={{
                  borderColor:
                    selectedPaymentMethod === "Cash"
                      ? primaryColor1
                      : undefined,
                }}
                onClick={() => {
                  setSelectedPaymentMethod("Cash");
                  setCollectCartDTO((prevState: CollectCartDTO) => ({
                    ...prevState,
                    paymentMethod: "Cash",
                    amountPaid: null,
                  }))
                }}
                className="payment-method"
              >
                <Grid
                  className="payment-method-button"
                  onClick={() => {
                    setSelectedPaymentMethod("Transfer");
                    setCollectCartDTO((prevState: CollectCartDTO) => ({
                      ...prevState,
                      paymentMethod: "Transfer",
                      amountPaid: null,
                    }))
                  }}
                  container
                >
                  <Grid
                    className={`payment-icon ${selectedPaymentMethod}`}
                    container
                  >
                    {icons.simpleCash({
                      width: 28,
                      height: 28,
                      fill:
                        selectedPaymentMethod === "Cash"
                          ? primaryColor1
                          : undefined,
                    })}
                  </Grid>
                  <Grid className="payment-title" container>
                    <Typography
                      color={
                        selectedPaymentMethod === "Cash"
                          ? primaryColor1
                          : grayColor
                      }
                      variant="paragraph-10"
                    >
                      Efectivo
                    </Typography>
                  </Grid>
                </Grid>
              </Button>
            </Grid>
            <Grid className="payment-methods-transfer" item xs={3} justifyContent="center">
              <Button
                style={{
                  borderColor:
                    selectedPaymentMethod === "Transfer"
                      ? primaryColor1
                      : undefined,
                }}
                onClick={() => {
                  setSelectedPaymentMethod("Transfer");
                  setCollectCartDTO((prevState: CollectCartDTO) => ({
                    ...prevState,
                    paymentMethod: "Transfer",
                    amountPaid: null,
                  }))
                }}
                className="payment-method"
              >
                <Grid className="payment-method-button" container>
                  <Grid className="payment-icon" container>
                    {icons.bank({
                      fill:
                        selectedPaymentMethod === "Transfer"
                          ? primaryColor1
                          : grayColor,
                    })}
                  </Grid>
                  <Grid className="payment-title" container>
                    <Typography
                      color={
                        selectedPaymentMethod === "Transfer"
                          ? primaryColor1
                          : grayColor
                      }
                      variant="paragraph-10"
                    >
                      Transfer.
                    </Typography>
                  </Grid>
                </Grid>
              </Button>
            </Grid>
            <Grid className="payment-methods-card" item xs={3} justifyContent="center">
              <Button
                style={{
                  borderColor:
                    selectedPaymentMethod === "Card"
                      ? primaryColor1
                      : undefined,
                }}
                onClick={() => {
                  setSelectedPaymentMethod("Card");
                  setCollectCartDTO((prevState: CollectCartDTO) => ({
                    ...prevState,
                    paymentMethod: "Card",
                    amountPaid: null,
                  }))
                }}
                className="payment-method"
              >
                <Grid className="payment-method-button" container>
                  <Grid className="payment-icon" container>
                    {icons.card({
                      width: 24,
                      height: 24,
                      color:
                        selectedPaymentMethod === "Card"
                          ? primaryColor1
                          : grayColor,
                    })}
                  </Grid>
                  <Grid className="payment-title" container>
                    <Typography
                      color={
                        selectedPaymentMethod === "Card"
                          ? primaryColor1
                          : grayColor
                      }
                      variant="paragraph-10"
                    >
                      Débito
                    </Typography>
                  </Grid>
                </Grid>
              </Button>
            </Grid>
            <Grid className="payment-methods-customer" item xs={3} justifyContent="center">
              <Button
                style={{
                  borderColor:
                    selectedPaymentMethod === "Customer"
                      ? primaryColor1
                      : undefined,
                }}
                onClick={() => {
                  setSelectedPaymentMethod("Customer");
                  setCollectCartDTO(defaultCollectCartDTO(props.cart));
                }}
                className="payment-method"
              >
                <Grid className="payment-method-button" container>
                  <Grid className="payment-icon" container>
                    <PeopleAltOutlinedIcon
                      style={{
                        width: 26,
                        height: 26,
                        color:
                          selectedPaymentMethod === "Customer"
                            ? primaryColor1
                            : grayColor
                      }}
                    />
                  </Grid>
                  <Grid className="payment-title" container>
                    <Typography
                      color={
                        selectedPaymentMethod === "Customer"
                          ? primaryColor1
                          : grayColor
                      }
                      variant="paragraph-10"
                    >
                      Fiar cliente
                    </Typography>
                  </Grid>
                </Grid>
              </Button>
            </Grid>
          </Grid>
          {selectedPaymentMethod === "Cash" && (
            <Grid className="collect-rent-drawer-cash" container>
              <Grid className="total-to-collect" container>
                <Grid container>
                  <Typography variant="paragraph-14">
                    Total a cobrar
                  </Typography>
                </Grid>
                <NumberInput
                  disabled
                  value={props.cart.totalAmount}
                  variant="line"
                  label=""
                  onChange={() => { }}
                />
              </Grid>
              <Grid className="amount-accredited-input" container>
                <NumberInput
                  value={collectCartDTO.amountPaid || ""}
                  variant="line"
                  onChange={(amountPaid: number) =>
                    setCollectCartDTO((prevState: CollectCartDTO) => ({
                      ...prevState,
                      amountPaid,
                    }))
                  }
                  label="Monto abonado"
                />
              </Grid>
              {(!!collectCartDTO.amountPaid && collectCartDTO.amountPaid < minAmountToPay) && (
                <Grid className="not-valid-amount-to-paid" container>
                  <Grid className="attention-box" container>
                    <Grid item xs={1} className="attention-box-icon" container>
                      {icons.circularWarning()}
                    </Grid>
                    <Grid item xs={11} className="attention-box-text" container>
                      <Typography
                        color={yellowColor3}
                        className="attention-box-title"
                        variant="paragraph-18"
                        type="bold"
                      >
                        Atención!
                      </Typography>
                      <Typography color={yellowColor3} variant="paragraph-14">
                        <>
                          El monto abonado no puede ser inferior a {numericFormatter(minAmountToPay)}
                        </>
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {(
                !!collectCartDTO.amountPaid && collectCartDTO.amountPaid >= minAmountToPay &&
                collectCartDTO.amountPaid < props.cart.totalAmount
              ) && (
                  <Grid className="remaining-balance" container>
                    <Grid container>
                      <Typography variant="paragraph-14">
                        Deuda perdonada
                      </Typography>
                    </Grid>
                    <NumberInput
                      disabled
                      value={Math.abs(props.cart.totalAmount - (collectCartDTO.amountPaid))}
                      variant="line"
                      label=""
                      onChange={() => { }}
                    />
                  </Grid>
                )}
              {!(!collectCartDTO.amountPaid || collectCartDTO.amountPaid <= props.cart.totalAmount) && (
                <>
                  <Grid className="checkbox-mora" container alignItems="center">
                    <Grid item xs={1}>
                      <Checkbox
                        widthIcons={18}
                        checked={!!collectCartDTO.changeReturned}
                        onClick={() =>
                          setCollectCartDTO((prevState: CollectCartDTO) => ({
                            ...prevState,
                            changeReturned: !prevState.changeReturned,
                          }))
                        }
                      />
                    </Grid>
                    <Grid item xs={11}>
                      <Typography variant="paragraph-16">
                        Proporcionar el vuelto
                      </Typography>
                    </Grid>
                  </Grid>
                  {!!collectCartDTO.changeReturned && (
                    <Grid className="returned-amount" container>
                      <Grid container>
                        <Typography variant="paragraph-14">
                          Vuelto
                        </Typography>
                      </Grid>
                      <NumberInput
                        disabled
                        value={Math.abs(props.cart.totalAmount - (collectCartDTO.amountPaid))}
                        variant="line"
                        label=""
                        onChange={() => { }}
                      />
                    </Grid>
                  )}
                </>
              )}
            </Grid>
          )}
          {(
            selectedPaymentMethod === "Transfer" ||
            selectedPaymentMethod === "Card"
          ) && (
              <Grid className="amount-accredited" container>
                <Grid container>
                  <Typography variant="paragraph-14">
                    Monto abonado
                  </Typography>
                </Grid>
                <NumberInput
                  disabled
                  value={Math.abs(props.cart.totalAmount)}
                  variant="line"
                  label=""
                  onChange={() => { }}
                />
              </Grid>
            )
          }
          {(selectedPaymentMethod === "Customer") && (
            <Grid className="customer-pay" container>
              {props.cart.cartPromotion.length ? (
                <Grid className="attention-box" container alignItems="center">
                  <Grid item xs={1} className="attention-box-icon" container>
                    {icons.circularWarning()}
                  </Grid>
                  <Grid item xs={11} className="attention-box-text" container>
                    <Typography
                      color={yellowColor3}
                      className="attention-box-title"
                      variant="paragraph-18"
                      type="bold"
                    >
                      Atención!
                    </Typography>
                    <Typography color={yellowColor3} variant="paragraph-14" type="semibold">
                      <>
                        No se pueden fiar las promociones
                      </>
                    </Typography>
                  </Grid>
                </Grid>
              ) : (
                <Grid container className="customer-pay-content">
                  <Grid container className="input-drawer create-customer-button">
                    <ButtonLevel
                      color="secondary"
                      title="Creá un nuevo cliente"
                      onClick={() => setOpenCreateCustomerDrawer(true)}
                      variant="text"
                      align="left"
                      fillWidth
                      size="large"
                      icon={{
                        position: "start",
                        icon: icons.add({ color: primaryColor2 })
                      }}
                    />
                  </Grid>
                  <Grid container className="input-drawer select-customer">
                    <SelectInput
                      itemSelected={customerSelected}
                      label="Cliente a fiar"
                      onChange={(customerId: number) =>
                        setCustomerSelected(customerId)
                      }
                      items={customerOptions}
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>
          )}
        </Grid>
      </SideDrawer>
      <CreateCustomerDrawer
        title="Agregá un cliente"
        setOpenDrawer={setOpenCreateCustomerDrawer}
        openDrawer={openCreateCustomerDrawer}
        updatePage={handleGetAllCustomers}
      />
    </Grid>
  );
}
