import { Grid } from "@mui/material";
import "./PromotionTable.scss"
import { useEffect, useRef, useState } from "react";
import BasicTable, { BasicTableStatus } from "../../kit/BasicTable";
import { defaultPaginatedResponse, defaultPaginateOptions, PaginatedResponse } from "../../../utils/generalServiceResponses";
import { getAllPromotions, Promotion } from "../../../services/promotions";
import ButtonLevel from "../../kit/Buttons";
import { dateFormatter, numericFormatter } from "../../../utils/formatters";
import { CreatePromotionDrawer } from "../CreatePromotionDrawer";
import Alert, { AlertType } from "../../kit/Alert";

interface Props {

}
export function PromotionTable(props: Props) {
  const [alertStatus, setAlertStatus] = useState<AlertType>("close");

  const [status, setStatus] = useState<BasicTableStatus>(
    "loading"
  );
  const [paginateOptions, setPaginateOptions] = useState(
    defaultPaginateOptions
  );
  const [promotionPaginated, setPromotionPaginated] = useState<
    PaginatedResponse<Promotion>
  >(defaultPaginatedResponse);
  const [createPromotionDrawer, setCreatePromotionDrawer] = useState(false);

  const handleGetAllPromotions = () => {
    setStatus("loading");
    (async () => {
      const response = await getAllPromotions(
        paginateOptions
      );
      if (response.data !== null) {
        setPromotionPaginated(response);
        setStatus("success");
      } else {
        setStatus("error");
      }
    })();
  };
  const timeoutIdRef = useRef<NodeJS.Timeout | null>(null);
  useEffect(() => {
    // clear the timeout and prevent the callback from being called
    if (timeoutIdRef.current !== null) clearTimeout(timeoutIdRef.current);

    timeoutIdRef.current = setTimeout(handleGetAllPromotions, 300);
  }, [
    paginateOptions.page,
    paginateOptions.limit,
    paginateOptions.search,
    paginateOptions,
  ]);

  return (
    <Grid container className="promotion-table">
      <BasicTable
        rightElement={
          <ButtonLevel
            fillWidth={false}
            title="Agregá una promoción"
            size="medium"
            onClick={() => setCreatePromotionDrawer(true)}
            variant="contained"
          />
        }
        status={status}
        head="Tus promos"
        search={{
          value: paginateOptions.search,
          onChange: (value) => {
            if (value === "") setStatus("loading");
            setPaginateOptions({ ...paginateOptions, search: value });
          },
          placeholder: "Buscar",
        }}
        columns={[
          "Id", "Nombre", "Monto", "Fecha limite"
        ]}
        rows={promotionPaginated.data.map((promotion: Promotion) => [
          promotion.id,
          promotion.name,
          numericFormatter(promotion.amount),
          dateFormatter(promotion.endDate, false)
        ])}
        options={[
          // {
          //   startIcon: icons.edit({ color: primaryColor1 }),
          //   label: "Editar",
          //   onClick: (rowIndex: number) => {
          //     const movement = movementPaginated.data[rowIndex];
          //     setMovementToUpdate(movement);
          //   },
          // },
          {
            label: "Eliminar",
            onClick: (rowIndex: number) => {
              const movement = promotionPaginated.data[rowIndex];

            },
          },
        ]}
        paginateOptions={{
          currentPage: paginateOptions.page,
          rowsPerPage: paginateOptions.limit,
          setCurrentPage: (page: number) => {
            setPaginateOptions({ ...paginateOptions, page });
          },
          totalItems: promotionPaginated.totalItems,
        }}
      />
      {alertStatus !== "close" && (
        <Alert
          setAlertStatus={setAlertStatus}
          severity={alertStatus}
          message={
            alertStatus === "success"
              ? "El movimiento se a creado con exíto"
              : "Ocurrío un error al crear el movimiento"
          }
        />
      )}
      <CreatePromotionDrawer
        setAlertStatus={setAlertStatus}
        setOpenDrawer={setCreatePromotionDrawer}
        openDrawer={createPromotionDrawer}
        updatePage={handleGetAllPromotions}
        title="Creá tu promoción"
      />
    </Grid>
  )
}