import axios from "axios";
import { API_URL } from "../../config/env";

export async function logIn(username: string, password: string) {
  try {
    const response = await axios.post(API_URL + "/login", { username, password });
    return response.data;
  } catch (e) {
    console.warn(e);
  }
}
